import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HowItWorksSteps } from "../constants/stepWork.js";
import StepWork from "./step-work/StepWork";
import SelectionModal from "./modal/SelectionModal";
import Toast from "./toast/Toast.jsx";

const HowItWorks = () => {
  const [showAnnonceModal, setShowAnnonceModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();
  const profileFilled = useSelector((state) => state.auth.profileFilled);
  const isAuthenticated = useSelector((state) => state.auth.user);

  // Get the first two elements
  const topSteps = HowItWorksSteps.slice(0, 2);

  // Get the last element
  const lastStep = HowItWorksSteps[HowItWorksSteps.length - 1];

  const handleCloseAnnonceModal = () => {
    setShowAnnonceModal(false);
  };

  const handleShowAnnonceModal = () => {
    setShowAnnonceModal(true);
  };

  const handleStepClick = (index) => {
    // Check if the user is authenticated and profile is filled
    if (!isAuthenticated) {
      navigate("/auth/login");
      return;
    }

    if (!profileFilled) {
      setShowToast(true);
      setTimeout(() => {
        navigate("/profile");
      }, 2500);
      return;
    }

    if (index === 0) { // First step
      handleShowAnnonceModal();
    } else if (index === 1) { // Second step
      navigate("/coloc/offres");
    } else if (index === 2) { // Last step
      navigate("/offres");
    }
  };

  return (
    <section id="works" className="works">
      <div className="container">
        <div className="section-header">
          <h2>
            <span
              style={{
                color: "#f44336",
                textTransform: "uppercase",
                backgroundColor: "white",
                padding: "0 10px",
                borderRadius: "5px",
              }}
            >
              Services{" "}
            </span>
            Offerts
          </h2>
          <p>Choisissez le service désiré.</p>
        </div>
        <div className="works-content">
          <div className="top-steps">
            {topSteps.map((step, index) => (
              <div key={index} onClick={() => handleStepClick(index)}>
                <StepWork
                  icon={step.icon}
                  title={step.title}
                  description={step.description}
                  link={step.link}
                  detailedSteps={step.detailedSteps}
                  example={step.example}
                  imageUrl={step.imageUrl}
                  stepNumber={index + 1}
                />
              </div>
            ))}
          </div>
          <div className="last-step">
            <div onClick={() => handleStepClick(2)}>
              <StepWork
                icon={lastStep.icon}
                title={lastStep.title}
                description={lastStep.description}
                link={lastStep.link}
                detailedSteps={lastStep.detailedSteps}
                example={lastStep.example}
                imageUrl={lastStep.imageUrl}
                stepNumber={3}
              />
            </div>
          </div>
        </div>
      </div>
      {showAnnonceModal && (
        <SelectionModal
          isOpen={showAnnonceModal}
          onClose={handleCloseAnnonceModal}
          links={[
            {
              path: "ajouter-offre-coloc",
              label: "Offres",
              icon: "FaSearch",
              description:
                "Vous avez déjà un logement et recherchez des colocataires pour le partager? <br/><b>Publiez votre offre ici</b> pour trouver les personnes idéales pour rejoindre votre espace.",
            },
            {
              path: "ajouter-demande-coloc",
              label: "Demandes",
              icon: "FaHome",
              description:
                "Vous cherchez une colocation existante ? <br/><b>Publiez votre demande ici </b> et trouvez le logement qui correspond à vos besoins.",
            },
          ]}
          title="Quel est votre besoin ?"
          description="Choisissez le type d'annonce qui correspond à votre situation."
        />
      )}
      {showToast && (
        <Toast
          title="Profil incomplet"
          desc="Veuillez compléter votre profil avant d'ajouter une annonce."
          delay={2500}
          onClose={() => setShowToast(false)}
        />
      )}
    </section>
  );
};

export default HowItWorks;
