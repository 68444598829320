import React, { useState } from 'react';
import AbonnementModal from './AbonnementModal';

const AbonnementSection = () => {
    const [selectedAbonnement, setSelectedAbonnement] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const abonnements = [
        { type: 'Gratuit', description: 'Accès limité aux fonctionnalités de base.' },
        { type: 'Standard', description: 'Accès à la plupart des fonctionnalités.' },
        { type: 'Premium', description: 'Accès complet à toutes les fonctionnalités, y compris les options premium.' }
    ];

    const handleChoose = (abonnement) => {
        setSelectedAbonnement(abonnement);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedAbonnement(null);
    };

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Abonnement</h2>
            <div className="row">
                {abonnements.map((abonnement, index) => (
                    <div key={index} className="col-md-4 mb-4" style={{ padding: "10px" }}>
                        <div 
                            className="card text-center" 
                            style={{ 
                                borderRadius: '10px', 
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', 
                                transition: 'transform 0.2s'
                            }}
                        >
                            <div 
                                className="card-body" 
                                style={{ 
                                    backgroundColor: 'white', 
                                    borderRadius: '10px 10px 0 0' 
                                }}
                            >
                                <h5 className="card-title">{abonnement.type}</h5>
                                <p className="card-text">{abonnement.description}</p>
                                <button 
                                    className="btn" 
                                    style={{ 
                                        backgroundColor: '#ff545a', 
                                        color: 'white', 
                                        borderRadius: '5px',
                                        margin: '10px'
                                    }}
                                    onClick={() => handleChoose(abonnement)} // Show modal on click
                                >
                                    Choisir
                                </button>
                                <button 
                                    className="btn" 
                                    style={{ 
                                        backgroundColor: '#00712D', 
                                        color: 'white', 
                                        borderRadius: '5px',
                                        margin: '10px'
                                    }}
                                >
                                    Acheter
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <AbonnementModal 
                abonnement={selectedAbonnement} 
                showModal={showModal} 
                handleClose={handleCloseModal} 
            />
        </div>
    );
};

export default AbonnementSection;
