
const staticImage = "https://images.unsplash.com/photo-1719937206158-cad5e6775044?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwxfHx8ZW58MHx8fHx8";

export const HowItWorksSteps = [
  {
    icon: "w1",
    title: "Proposer une colocation",
    description: "Vous disposez d'un logement ou vous en êtes locataire, vous cherchez un coloc ?<br/> Cliquez pour insérer vos informations",
    detailedSteps: [{
      title: "cliquer sur ajouter une annonce sur la barre de navigation", image: staticImage,
    }, {
      title: "Préciser votre besoin ", image: staticImage,
    }, {
      title: "Remplissez la formulaire de description de votre annonce", image: staticImage,
    }, {
      title: "Confirmer votre annonce", image: staticImage,
    }],

  },
  {
    icon: "w2",
    title: "Chercher un loyer ou un colocataire",
    description: "Exprimez votre besoin, insérez vos informations et Contactez les colocataires potentiels pour discutez des détails et voir si ça vous convient.",
    detailedSteps: [
      {
        title: "Selectionner la ville", image: staticImage,
      }, {
        title: "Choisir le local ", image: staticImage,
      },
    ],
  },
  {
    icon: "w3",
    title: "Confirmation de la coloc",
    description: "Une fois votre recherche satisfaite, signaler le sur la plateforme et commencez votre nouvelle vie en colocation.",
    detailedSteps: [{
      title: "Choisissez votre colocation idéale", image: staticImage,
    },
    ],
  }
];