import React from "react";
import "./AlertError.css";
import { useError } from "../providers/ErrorProvider";

const AlertError = () => {
  const { error, hideError } = useError();

  if (!error) return null;

  return (
    <div className="error-alert">
      <span className="error-message">{error}</span>
      <button className="close-button" onClick={hideError}>
        &times;
      </button>
    </div>
  );
};

export default AlertError;
