// ProfileInfoCard.jsx
import React from "react";
import { User, Mail, Phone, Globe, Tag, Info } from "lucide-react";
import "./UserProfilePublicInfo.css";

const ProfileInfoCard = ({ profileData }) => {
  const userInfo = profileData.userInfo;
  if (!userInfo) return null;

  const fields = [
    { key: "prenom", label: "Prénom", icon: <User size={18} /> },
    { key: "nom", label: "Nom", icon: <User size={18} /> },
    { key: "age", label: "Âge", icon: <Info size={18} /> },
    { key: "email", label: "Email", icon: <Mail size={18} /> },
    { key: "telephone", label: "Téléphone", icon: <Phone size={18} /> },
    { key: "apropos", label: "À propos", icon: <Info size={18} /> },
    {
      key: "langues",
      label: "Langues",
      icon: <Globe size={18} />,
      format: (value) => value.map((langue) => langue).join(", "),
    },
    {
      key: "tags",
      label: "Tags",
      icon: <Tag size={18} />,
      format: (value) => value.map((tag) => tag).join(", "),
    },
  ];

  return (
    <div className="profile-info-card ">
      <h2 className="profile-info-title">Vos Informations Publiques</h2>
      <div className="profile-info-grid">
        {fields.map((field) => (
          <div key={field.key} className="profile-info-item">
            <div className="profile-info-label">
              {field.icon}
              <span>{field.label}</span>
            </div>
            <p className="profile-info-value">
              {field.format
                ? field.format(userInfo[field.key])
                : userInfo[field.key] || "N/A"}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfileInfoCard;
