import React, { useState } from 'react';
import '../../assets/css/form.css'

const TelephoneVerification = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isTeleVerified, setIsTeleVerified] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
    setIsTeleVerified(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsTeleVerified(false);
  };

  const handleVerifyEmail = () => {
    setTimeout(() => {
      setIsTeleVerified(true);
    }, 1000);
  };

  const handleVerifyTele = () => {
    setTimeout(() => {
      setIsEmailVerified(true);
    }, 1000);
  };

  return (
    <div className="coloc-form">
      <div className="nice-form-group">
        <label htmlFor="phone">Numéro de téléphone</label>
        <div className="input-wrapper">
          <input
            type="tel"
            id="phone"
            className='form-control'
            value={phoneNumber}
            onChange={handlePhoneChange}
            placeholder="0600000000"
          />
          <button onClick={handleVerifyTele} style={{margin:"10px"}} className="verify-button btn btn-primary">
            Vérifier
          </button>
        </div>
        {isTeleVerified && (
          <span className="verification-status">Numéro de téléphone vérifié</span>
        )}
      </div>
      <div className="nice-form-group">
        <label htmlFor="phone">Email</label>
        <div className="input-wrapper">
          <input
            type="tel"
            id="phone"
            className='form-control'
            value={email}
            onChange={handleEmailChange}
            placeholder="exemple@gmail.com"
          />
          <button onClick={handleVerifyEmail} style={{margin:"10px"}} className="verify-button btn btn-primary">
            Vérifier
          </button>
        </div>
        {isEmailVerified && (
          <span className="verification-status">Email vérifié</span>
        )}
      </div>
    </div>
  );
};

export default TelephoneVerification;