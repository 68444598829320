export const staticVilles = [
    {
      id: 1,
      nom: 'Marrakech',
      image: 'https://images.unsplash.com/photo-1597212618440-806262de4f6b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fG1hcnJha2VjaHxlbnwwfHwwfHx8MA%3D%3D',
      colocs: [
        {
          id: 1,
          title: 'Coloc 1',
          description: 'Description 1',
          price: 1000,
          image: 'https://via.placeholder.com/150',
        },
        {
          id: 2,
          title: 'Coloc 2',
          description: 'Description 2',
          price: 2000,
          image: 'https://via.placeholder.com/150',
        },
        {
          id: 3,
          title: 'Coloc 3',
          description: 'Description 3',
          price: 3000,
          image: 'https://via.placeholder.com/150',
        },
      ],
    },
    {
      id: 2,
      nom: 'Casablanca',
      image : 'https://plus.unsplash.com/premium_photo-1697729726646-c01faa307c41?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Y2FzYWJsYW5jYXxlbnwwfHwwfHx8MA%3D%3D',
      colocs: [
        {
          id: 1,
          title: 'Coloc 1',
          description: 'Description 1',
          price: 1000,
          image: 'https://via.placeholder.com/150',
        },
        {
          id: 2,
          title: 'Coloc 2',
          description: 'Description 2',
          price: 2000,
          image: 'https://via.placeholder.com/150',
        },
        {
          id: 3,
          title: 'Coloc 3',
          description: 'Description 3',
          price: 3000,
          image: 'https://via.placeholder.com/150',
        },
      ],
    },
    {
      id: 3,
      nom: 'Agadir',
      image : 'https://images.unsplash.com/photo-1702840648310-bfaacb533afb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGFnYWRpcnxlbnwwfHwwfHx8MA%3D%3D',
      colocs: [
        {
          id: 1,
          title: 'Coloc 1',
          description: 'Description 1',
          price: 1000,
          image: 'https://via.placeholder.com/150',
        },
        {
          id: 2,
          title: 'Coloc 2',
          description: 'Description 2',
          price: 2000,
          image: 'https://via.placeholder.com/150',
        },
        {
          id: 3,
          title: 'Coloc 3',
          description: 'Description 3',
          price: 3000,
          image: 'https://via.placeholder.com/150',
        },
      ],
    },
    // {
    //   id: 4,
    //   nom: 'Marseille',
    // }, {
    //   id: 5,
    //   nom: 'Marseille',
    // }, {
    //   id: 6,
    //   nom: 'Marseille',
    // },
  ]