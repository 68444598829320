import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBlogById } from "../../services/apis";
import SEO from "../../seo/SEO"

const BlogDetails = () => {
    const { id } = useParams();
    const [blog, setBlog] = useState(null);
    const [error, setError] = useState(null);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        const fetchBlogById = async () => {
            try {
                const res = await getBlogById(id);
                setBlog(res.data);
            } catch (error) {
                setError(error.message);
                console.error(error);
            }
        };

        fetchBlogById();
    }, [id]);

    if (error) {
        return <div>Erreur: {error}</div>;
    }

    if (!blog) {
        return <div>Chargement...</div>;
    }

    // Format the date in French
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('fr-FR', options);
    };

    const truncateString = (str, length) => {
        if (str?.length > length) {
            return str?.slice(0, length) + '...';
        }
        return str;
      }

    const formattedDate = formatDate(blog.createdAt);

    return (
        <div style={{ padding: '20px', maxWidth: "800px", margin: "35px auto" }}>
            <SEO
                title={blog.title} 
                description={truncateString(blog.content,90)} 
                image={blog.image} 
                url={`https://colocky.ma/blogs/${blog.id}`}
            />
            <div className="blog-container" style={{ textAlign: "left" }}>
                <h1 style={{ marginTop: "10px", fontSize: "2.5em" }}>{blog.title}</h1>
                <p style={{ color: "#888", fontSize: "14px", marginBottom: "20px" }}>
                    Créé le {formattedDate}
                </p>
                <img
                    src={blog.image}
                    alt={blog.title}
                    style={{ maxWidth: "100%", height: "auto", borderRadius: "10px", marginTop: "10px" }}
                />
                <p style={{ marginTop: "20px", fontSize: "1.2em", lineHeight: "1.6" }}>{blog.content}</p>
            </div>
        </div>
    );
};

export default BlogDetails;
