import React, { useState, useEffect } from 'react';
import { getDemande, updateDemande } from '../../services/apis';
import { useParams } from 'react-router-dom';
import '../../assets/css/profile.css';

const DemandeDetails = () => {
    const { id } = useParams();
    const [demande, setDemande] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [dateDemande, setDateDemande] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDemande = async () => {
            try {
                const response = await getDemande(id);
                setDemande(response.data);
                setDateDemande(response.dateDemande);
                setLoading(false);
            } catch (err) {
                setError('Error fetching demande');
                setLoading(false);
            }
        };
        fetchDemande();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateDemande(id, { dateDemande });
            setIsEditing(false);
            setDemande((prevData) => ({ ...prevData, dateDemande }));
        } catch (err) {
            setError('Error updating demande');
        }
    };

    if (loading) return <div className="profile text-center">Loading...</div>;
    if (error) return <div className="profile alert alert-danger">{error}</div>;

    return (
        <div className="profile">
            <section className="demo-page-content">
                <h1 className="package-name">Details de la Demande</h1>
                <div className="nice-form-group">
                    {isEditing ? (
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="dateDemande">Date de demande:</label>
                            <input
                                type="date"
                                id="dateDemande"
                                className='form-control'
                                value={dateDemande}
                                onChange={(e) => setDateDemande(e.target.value)}
                            />
                            <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                                <button type="submit" className="to-repo" style={{ marginRight: '0.5rem' }}>Enregistrer</button>
                                <button type="button" className="to-reset" onClick={() => setIsEditing(false)}>Annuler</button>
                            </div>
                        </form>
                    ) : (
                        <div>
                            <p><strong>Date de demande:</strong> {new Date(demande?.dateDemande).toLocaleDateString()}</p>
                            <p><strong>Logement:</strong> {demande?.logement?.adresse || 'N/A'}</p>
                            <p><strong>Description:</strong> {demande?.logement?.description || 'N/A'}</p>
                            <p><strong>Prix:</strong> {demande?.logement?.prix || 'N/A'}</p>
                            <p><strong>Type:</strong> {demande?.logement?.type || 'N/A'}</p>
                            <p><strong>Demandeur:</strong> {demande?.demandeur?.nom} {demande?.demandeur?.prenom}</p>
                            <p><strong>Email:</strong> {demande?.demandeur?.email}</p>
                            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                                <button className="to-repo" onClick={() => setIsEditing(true)}>Modifier</button>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </div>
    );
};
export default DemandeDetails;
