import './LogementActions.css';

const LogementActions = ({ logementId, onMarquerCommeLouee, onMarquerCommeDisponible, onModifierOffre, statut }) => {
  return (
    <div className="logement-actions">
      {statut !== 'LOUE' ? (
        <button
          className="action-button primary"
          onClick={() => onMarquerCommeLouee(logementId)}
        >
          Marquer comme déjà louée
        </button>
      ) : (
        <button
          className="action-button green"
          onClick={() => onMarquerCommeDisponible(logementId)}

        >
          Rendre disponible
        </button>
      )}
      <button
        className="action-button secondary"
        onClick={() => onModifierOffre(logementId)}
      >
        Modifier
      </button>
    </div>
  );
};

export default LogementActions;
