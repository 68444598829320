import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SEO from '../seo/SEO';
import SelectionModal from './modal/SelectionModal';
import Toast from './toast/Toast';

const HeroSection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const [showAnnonceModal, setShowAnnonceModal] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const scrollToSection = (id) => {
    if (location.pathname !== "/") {
      navigate('/', { state: { scrollTo: id } });
    } else {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleShowAnnonceModal = () => {
    if (!auth.profileFilled) {
      setShowToast(true);
      setTimeout(() => {
        navigate("/profile");
      }, 2500);
    } else if (auth.demandeFilled) {
      navigate("/ajouter-offre-coloc");
    } else {
      setShowAnnonceModal(true);
    }
  };

  const handleCloseAnnonceModal = () => {
    setShowAnnonceModal(false);
  };
  
  return (
    <section id="home" className="welcome-hero">
      <SEO
        title="Trouvez Votre Colocation Idéale au Maroc | Colocky"
        description="Meilleur endroit pour proposer, chercher et trouver une colocation idéale au Maroc."
        url={window.location.href}
      />
      <div className="hero-overlay"></div>
      <div className="container">
        <div className="welcome-hero-txt">
          <h1 className="main-title">Trouvez Votre Colocation Idéale</h1>
          <h2 className="subtitle">Meilleur endroit pour proposer, chercher et trouver une Colocation de tout type</h2>
          
          <div className="cta-button-container">
            <a href="#explore" id="cta-button" onClick={(e) => {
              e.preventDefault();
              handleShowAnnonceModal();
            }}>
              Ajouter une annonce
            </a>

            <a href="#explore" id="cta-button" onClick={(e) => {
              e.preventDefault();
              scrollToSection('explore');
            }}>
              Commencer la recherche
            </a>
          </div>
        </div>
        {/* <div className="welcome-hero-serch-box">
          <div className="welcome-hero-form">
            <div className="single-welcome-hero-form">
              <h3>Catégorie ?</h3>
              <form action="index.html">
                <input type="text" placeholder="Ex: STudio, Villa, Appartement" />
              </form>
              <div className="welcome-hero-form-icon">
                <i className="flaticon-list-with-dots"></i>
              </div>
            </div>
            <div className="single-welcome-hero-form">
              <h3>Ville</h3>
              <form action="index.html">
                <input type="text" placeholder="Ex: Marrakech, Casa, Rabat" />
              </form>
              <div className="welcome-hero-form-icon">
                <i className="flaticon-gps-fixed-indicator"></i>
              </div>
            </div>
          </div>
          <div className="welcome-hero-serch">
            <button className="welcome-hero-btn" onClick={() => window.location.href='#'}>search <i data-feather="search"></i></button>
          </div>
        </div> */}
      </div>

      {showAnnonceModal && (
        <SelectionModal
          isOpen={showAnnonceModal}
          onClose={handleCloseAnnonceModal}
          links={[
            {
              path: "ajouter-offre-coloc",
              label: "Offres",
              title: "Ajouter une offre ",
              icon: "FaSearch",
              description:
                "Vous avez déjà un logement et recherchez des colocataires pour le partager? <br/><b>Publiez votre offre ici</b> pour trouver les personnes idéales pour rejoindre votre espace.",
            },
            {
              path: "ajouter-demande-coloc",
              label: "Demandes",
              title: "Ajouter une demande",
              icon: "FaHome",
              description:
                "Vous cherchez une colocation existante ? <br/><b>Publiez votre demande ici </b> et trouvez le logement qui correspond à vos besoins.",
            },
          ]}
          title="Quel est votre besoin ?"
          description="Choisissez le type d'annonce qui correspond à votre situation."
        />
      )}

      {showToast && (
        <Toast
          title="Profil incomplet"
          desc="Veuillez compléter votre profil avant d'ajouter une annonce."
          delay={2500}
          onClose={() => setShowToast(false)}
        />
      )}
    </section>
  );
}

export default HeroSection;