import React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import './PaginationControls.css';

const PaginationControls = ({ currentPage, pageSize, totalItems, onPreviousPage, onNextPage }) => {
  const totalPages = Math.ceil(totalItems / pageSize);

  if (totalPages <= 1) {
    return null;
  }

  return (
    <div className="pagination-controls">
      <button
        className="pagination-button"
        onClick={onPreviousPage}
        disabled={currentPage === 0}
      >
        <FaArrowLeft />
      </button>
      <span className="page-indicator">
        Page {currentPage + 1} 
      </span>
      <button
        className="pagination-button"
        onClick={onNextPage}
        disabled={totalItems <= (currentPage + 1) * pageSize}
      >
        <FaArrowRight />
      </button>
    </div>
  );
};

export default PaginationControls;