
import React from "react";
import './StepWork.css'
// import StepWorkModal from "./step-work-modal/StepWorkModal";
// import { FaArrowRight } from "react-icons/fa";
// import Modal from './Modal';


const formatDescription = (text) => {
  return text.split('<br/>').map((line, index, array) => (
    <React.Fragment key={index}>
      {line}
      {index < array.length - 1 && <br />}
    </React.Fragment>
  ));
};


const StepWork = ({
  icon,
  title,
  description,
  detailedSteps,
  example,
  imageUrl,
  link,
  stepNumber,
}) => {
  return (
    <>
      <div className=""
        style={{
          fontFamily: 'Poppins, sans-serif',
        }}
      >
        {/* <div className="single-how-works"> */}
        <div className="step-work">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <img src={`/images/works/${icon}.png`} alt="" />
          </div>
          <h2>
            {title
              .split(" ")
              .map((word, index) =>
                index === 1 ? <span key={index}> {word} </span> : ` ${word} `
              )}
          </h2>
          <p
            style={{ fontFamily: 'Poppins, sans-serif' }}
          >{formatDescription(description)}</p>
        </div>
      </div>
    </>
  );
};

export default StepWork;
