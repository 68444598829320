

export const ELogementType = Object.freeze({
    CHAMBRE: "CHAMBRE",
    VILLA: "VILLA",
    APPARTEMENT: "APPARTEMENT",
    STUDIO: "STUDIO",
});

export const EColocStatut = Object.freeze({
    EN_ATTENTE: "EN_ATTENTE",
    IMMEDIATE: "IMMEDIATE",
    LOUE: "LOUE",
    DISPONIBLE: "DISPONIBLE",
});
