import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setDemandeFilled } from '../../../../state/slices/authSlice';
import Toast from '../../../../components/toast/Toast.jsx';
import StepOneUserForm from './StepOneUserForm';
import StepTwoPreferencesForm from './StepTwoPreferencesForm';
import StepThreeConfirmationForm from './StepThreeConfirmationForm';
import { createDemande, getAllVilles } from '../../../../services/apis';
import '../../../../assets/css/form.css';
import { useNavigate } from 'react-router-dom';

const steps = [
  {
    step: 1,
    title: 'Utilisateur',
    svgPath: (
      <>
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
        <circle cx="12" cy="7" r="4"></circle>
      </>
    ),
  },
  {
    step: 2,
    title: 'Préférences',
    svgPath: (
      <>
        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
      </>
    ),
  },
  {
    step: 3,
    title: 'Confirmation',
    svgPath: (
      <>
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
        <circle cx="12" cy="7" r="4"></circle>
      </>
    ),
  },
];

function MultiStepDemandeForm() {
  const [step, setStep] = useState(1);
  const { user } = useSelector((state) => state.auth);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toast, setToast] = useState({ show: false, title: '', desc: '' });
  const [formData, setFormData] = useState({
    titre: "Recherche colocataire",
    description: "",
    villeId: "",
    quartier: "",
    budget: "",
    dateDisponibilite: "IMMEDIATE",
    specificDate: "",
    duree: "",
    demandeurId: user ? user.id : "",
  });
  
  const [villes, setVilles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllVilles();
        setVilles(response.data.content);
      } catch (error) {
        console.error('Error fetching villes', error);
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCityChange = (e) => {
    const selectedVilleId = Number(e.target.value);
    const selectedVille = villes.find(ville => ville.id === selectedVilleId);
    if (selectedVille) {
      setFormData({
        ...formData,
        villeId: selectedVilleId,
        villeName: selectedVille.nom,
      });
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const previousStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSend = {
        ...formData,
        budget: parseFloat(formData.budget),
        duree: parseInt(formData.duree),
        villeId: parseInt(formData.villeId),
        dateDisponibilite: formData.dateDisponibilite === 'IMMEDIATE' ? '2000-01-01' : formData.specificDate,
      };
      const response = await createDemande(dataToSend);
      console.log('Demande created:', response.data);
      setError(null); // Update demandeFilled state in Redux and localStorage
      dispatch(setDemandeFilled(true));
      
      // Show success message using Toast
      setToast({
        show: true,
        title: 'Succès',
        desc: 'Votre demande a été soumise avec succès!',
      });
      
      // Redirect after a delay to allow the toast to be visible
      setTimeout(() => {
        setToast({ show: false, title: '', desc: '' });
        navigate('/');
      }, 3000); // 3 seconds delay
    } catch (error) {
      console.error('Error creating demande', error);
      setError(error.response.data.message);
    }
  };
  
  const renderStepContent = () => {
    switch (step) {
      case 1:
        return <StepOneUserForm formData={formData} user={user} nextStep={nextStep} handleChange={handleChange} />;
      case 2:
        return (
          <StepTwoPreferencesForm
            formData={formData}
            handleChange={handleChange}
            handleCityChange={handleCityChange}
            previousStep={previousStep}
            nextStep={nextStep}
            villes={villes}
          />
        );
      case 3:
        return (
          <StepThreeConfirmationForm
            formData={formData}
            previousStep={previousStep}
            handleSubmit={handleSubmit}
            setError={setError}
          />
        );
      default:
        return (
          <div>
            <h2><u>Merci pour votre soumission</u></h2>
            <p>Votre demande a été soumise avec succès et sera envoyée à l'administrateur pour vérification.</p>
            <div className="nice-form-group">
              <button className="default" type="button" onClick={() => navigate('/')}>
                Retour à l'accueil
              </button>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="demo-page">
      <div className="demo-page-navigation">
        <nav>
          <ul>
            {steps.map(({ step: stepNum, title, svgPath }) => (
              <li key={stepNum}>
                  <a href={`#step${stepNum}`}
                  className={step === stepNum ? 'active bg-danger' : ''}
                  onClick={() => setStep(stepNum)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    {svgPath}
                  </svg>
                  {title}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="demo-page-content">
        {
          error && (
            <div className="alert alert-danger">
              {error}
            </div>
          )
        }
        <div className="nice-form">
        <h2 style={{ textAlign: 'center', fontWeight: 'bold', color: '#ff545a' }}>
        Ajouter une demande
        </h2>
          <form onSubmit={handleSubmit}>
            {renderStepContent()}
          </form>
        </div>
      </div>
      {toast.show && (
        <Toast
          title={toast.title}
          desc={toast.desc}
          delay={3000}
          onClose={() => setToast({ show: false, title: '', desc: '' })}
        />
      )}
    </div>
  );
}

export default MultiStepDemandeForm;
