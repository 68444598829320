import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({ title, description, image, url }) => {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content='colocation, coliving, Maroc, logement partagé, colocation au Maroc, coliving Casablanca, étudiants Rabat' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <link rel='canonical' href={url || window.location.href} />

      {/* Open Graph tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url || window.location.href} />
      {image && <meta property="og:image" content={image} />}
    </Helmet>
  )
}

export default SEO;
