import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useError } from "../../providers/ErrorProvider";
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";
import ColocItemOffre from "./coloc-item-offre/ColocItemOffre";
import PaginationControls from "../../pagination/PaginationControls";

const ColocList = ({ getLogementsApi, title, subtitle, selectedId }) => {
  const { villeId } = useParams();
  const [logements, setLogements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(3);
  const { showError } = useError();

  useEffect(() => {
    const fetchLogements = async () => {
      try {
        setLoading(true);
        const response = await getLogementsApi(currentPage, pageSize, villeId);
        setLogements(response.data.content);
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des logements:", error);
        setLoading(false);
      }
    };

    fetchLogements();
  }, [getLogementsApi, villeId, currentPage, pageSize, showError]);

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (logements.length === pageSize) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <section style={{ fontFamily: "Poppins" }} id="explore" className="explore">
      <div className="container">
        <div className="section-header">
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </div>

        <div className="explore-content">
          <div
            className="row"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            {logements.length > 0 ? (
              logements
                .filter(
                  (logement) =>
                    logement.statut === "DISPONIBLE" && logement.id !== Number(selectedId)
                )
                .map((logement) => (
                  <ColocItemOffre
                    key={logement.id}
                    id={logement.id}
                    titre={logement.titre || "N/A"}
                    adresse={logement.adresse || "N/A"}
                    description={logement.description || "N/A"}
                    prix={logement.prix || "N/A"}
                    superficie={logement.superficie || 0}
                    nbrColoc={logement.nbrColoc || 0}
                    type={logement.logementType || "N/A"}
                    ville={logement.ville || {}}
                    quartier={logement.quartier || "N/A"}
                    proprietaire={logement.proprietaire || {}}
                    photos={logement.photos || []}
                    dateDisponibilite={logement.dateDisponibilite || "N/A"}
                    duree={logement.duree || "N/A"}
                    statut={logement.statut || "N/A"}
                    createdAt={logement.createdAt}
                  />
                ))
            ) : (
              <div className="flex-row-center">
                <p>Aucun logement trouvé</p>
              </div>
            )}
          </div>

          {/* Pagination Controls */}
          <PaginationControls
            currentPage={currentPage}
            pageSize={pageSize}
            totalItems={logements.length}
            onPreviousPage={handlePreviousPage}
            onNextPage={handleNextPage}
          />
        </div>
      </div>
    </section>
  );
};

export default ColocList;
