import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useError } from "../../providers/ErrorProvider";
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";
import ColocItemOffre from "./coloc-item-offre/ColocItemOffre";
import { FaArrowRight } from "react-icons/fa";

const ColocHomeList = ({ getLogementsApi, title, LinktoMore }) => {
  const [logements, setLogements] = useState([]);
  const [loading, setLoading] = useState(true);
  const { showError } = useError();

  useEffect(() => {
    const fetchLogements = async () => {
      try {
        setLoading(true);
        const response = await getLogementsApi();
        setLogements(response.data.content);
        console.log("logements", response.data.content);
        setLoading(false);
      } catch (error) {
        // showError("Erreur lors de la récupération des logements : " + error.message);
        console.error("Erreur lors de la récupération des logements:", error);
        setLoading(false);
      }
    };

    fetchLogements();
  }, [getLogementsApi, showError]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <section id="explore" className="explore">
      <div className="container">
        <div className="section-header">
          <h2>{title}</h2>
          <p>Explorer les colocations récemment publiées</p>
        </div>

        <div className="explore-content">
          <div
            className="row"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: title === "Demandes" ? "0rem" : "10px",
            }}
          >
            {logements.length > 0 &&
              logements
              .filter(logement => logement.statut === "DISPONIBLE")
              .map((logement) => (
                <ColocItemOffre
                  key={logement.id}
                  id={logement.id}
                  titre={logement.titre || "N/A"}
                  adresse={logement.adresse || "N/A"}
                  description={logement.description || "N/A"}
                  prix={logement.prix || "N/A"}
                  superficie={logement.superficie || 0}
                  nbrColoc={logement.nbrColoc || 0}
                  type={logement.logementType || "N/A"}
                  ville={logement.ville || {}}
                  quartier={logement.quartier || "N/A"}
                  proprietaire={logement.proprietaire || {}}
                  photos={logement.photos || []}
                  dateDisponibilite={logement.dateDisponibilite || "N/A"}
                  duree={logement.duree || "N/A"}
                  statut={logement.statut || "N/A"}
                  createdAt={logement.createdAt}
                />
              ))}
          </div>
          {logements.length === 0 ? (
            <div className="flex-row-center">
              <p>Aucun logement trouvé</p>
            </div>
          ) : (
            <div className="flex-row-center">
              {LinktoMore && (
                <Link
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    color: "#ff545a",
                    textDecoration: "none",
                    padding: "0.5rem 1rem",
                    border: "1px solid #ff545a",
                    marginTop: "2rem",
                  }}
                  to={LinktoMore}
                >
                  Voir plus <FaArrowRight />
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ColocHomeList;
