import React, { useState } from 'react';
import { createAvis } from '../../../services/apis';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Rating from 'react-rating-stars-component';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import Toast from '../../toast/Toast.jsx';
import './add-review.css';

const schema = yup.object().shape({
  commentaire: yup.string().required('Le commentaire est obligatoire'),
  note: yup.number()
    .typeError('La note doit être un nombre')
    .required('La note est obligatoire')
    .min(0, 'La note minimale est 0')
    .max(5, 'La note maximale est 5'),
});

const AddReview = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [toast, setToast] = useState(null);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      commentaire: '',
      note: null,
      auteurId: user ? user.id : null,
    }
  });

  const onSubmit = (data) => {
    createAvis(data)
      .then(() => {
        setToast({ title: 'Succès', desc: 'Avis ajouté avec succès!', delay: 2500 });
        setTimeout(() => {
          setToast({ show: false, title: '', desc: '' });
          navigate('/');
        }, 2400);
      })
      .catch((error) => {
        setToast({ title: 'Erreur', desc: 'Une erreur s\'est produite lors de l\'ajout de l\'avis!', delay: 3000 });
        console.error('Une erreur s\'est produite lors de l\'ajout de l\'avis!', error);
      });
  };

  return (
    <Container className="formContainer formContainer-review">
      <h2 className='formTitle'>Ajouter Votre Avis</h2>
      <Row>
        <Col md={{ span: 12, offset: 3 }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="formGroup">
              <Form.Label>Commentaire :</Form.Label>
              <Form.Control
                as="textarea"
                {...register('commentaire')}
                isInvalid={!!errors.commentaire}
              />
              <Form.Control.Feedback className='invalid' type="invalid">
                {errors.commentaire?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="formGroup">
              <Form.Label>Note :</Form.Label>
              <Rating
                count={5}
                size={24}
                activeColor="#ffd700"
                onChange={(value) => setValue('note', value)}
              />
              {errors.note && <p className='invalid'>{errors.note.message}</p>}
            </Form.Group>
            <Button className='default' variant='' type="submit">Soumettre</Button>
          </Form>
        </Col>
      </Row>

      {/* Render Toast if there is a message to show */}
      {toast && (
        <Toast
          title={toast.title}
          desc={toast.desc}
          delay={toast.delay}
          onClose={() => setToast(null)} // Close toast after delay
        />
      )}
    </Container>
  );
};

export default AddReview;
