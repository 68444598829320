import React from 'react';
import './CityFilterBar.css';

const CityFilterBar = ({ villes, selectedVilleId, onCityFilterChange, onResetFilter }) => {
  return (
    <div className="city-filter-bar">
      <h5>Filtrer par ville</h5>
      <ul className="city-list">
        {villes.map((ville) => (
          <li
            key={ville.id}
            className={`city-item ${selectedVilleId === ville.id ? "active" : ""}`}
            onClick={() => onCityFilterChange(ville.id)}
          >
            {ville.nom}
          </li>
        ))}
      </ul>
      <button
        className="reset-button"
        onClick={onResetFilter}
        disabled={!selectedVilleId}
      >
        Réinitialiser le filtre
      </button>
    </div>
  );
};

export default CityFilterBar;