import React from 'react';
import { FileText, DollarSign, Calendar, MapPin, Building, Globe, CheckCircle } from 'lucide-react';
import './DemandDetailsCard.css';

const DemandDetailsCard = ({ demandeData }) => {
  if (!demandeData) return null;

  const fields = [
    { key: "titre", label: "Titre", icon: <FileText /> },
    { key: "budget", label: "Budget", icon: <DollarSign /> },
    {
      key: "dateDisponibilite",
      label: "Date de disponibilité",
      icon: <Calendar />,
      format: (value) => {return value === "2000-01-01" ? "Imediatement": new Date(value).toLocaleDateString()},
    },
    { key: "quartier", label: "Quartier", icon: <MapPin /> },
    {
      key: "ville",
      label: "Ville",
      icon: <Building />,
      format: (value) => value.nom,
    },
    { key: "description", label: "Description", icon: <Globe /> },
  ];

  return (
    <div className="demand-details-container">
      <h2 className="demand-details-title">Détails de la Demande</h2>
      <div className="demand-details-grid">
        {fields.map((field) => (
          <div key={field.key} className="demand-details-card">
            <div className="card-icon">
              {field.icon}
            </div>
            <h3 className="card-title">{field.label}</h3>
            <p className="card-text">
              {field.format
                ? field.format(demandeData[field.key])
                : demandeData[field.key] || "N/A"}
            </p>
          </div>
        ))}
      </div>
      <div className={`verification-status ${demandeData.verified ? 'verifiedd' : 'unverified'}`}>
        <div className="card-icon">
          <CheckCircle />
        </div>
        <h3 className="card-title">Vérification</h3>
        <p className="card-text">
          {demandeData.verified ? "Vérifié" : "En cours de vérification"}
        </p>
      </div>
    </div>
  );
};

export default DemandDetailsCard;
