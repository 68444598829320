import React, { useEffect, useState } from "react";
import "./ShowNumberModal.css";
import { FaExclamationCircle, FaPhone } from "react-icons/fa";
import { useSelector } from "react-redux";
import { getTelephoneById } from "../../../services/apis";

const ShowNumberModal = ({
  isOpen,
  onClose,
  title,
  description,
  sousDescription,
  phoneNumber,
  idProp
}) => {
  const [tele, setTele] = useState("");
  const {user}= useSelector(state=>state.auth);

  useEffect(()=>{
    const handleFindTelephone = async () => {
      try {
        const response = await getTelephoneById(idProp, user?.id);
        setTele(response.data);
        } catch (error) {
        console.log(error.response ? error.response.data : "An error occurred while retrieving the telephone.");
      }
    };
    handleFindTelephone();
  },[user?.id, idProp])



  if (!isOpen) return null;

  return (
    <div className="show-number-modal-overlay">
      <div className="show-number-modal-content">
        <button className="show-number-modal-close" onClick={onClose}>
          &times;
        </button>
        <div className="show-number-modal-icon">
          <FaExclamationCircle />
        </div>
        <h2 className="show-number-modal-title"> {title}</h2>
        <p className="show-number-modal-description">
          {description}
        </p>
        <p className="show-number-modal-call-text">
          {sousDescription}
        </p>
        <div className="show-number-modal-phone">
          <FaPhone />
          <span>{tele || phoneNumber}</span>
        </div>
      </div>
    </div>
  );
};

export default ShowNumberModal;
