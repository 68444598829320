import { useEffect, useState } from "react"
import { getAllAbonnmentsByIdUtilisateur } from "../../services/apis";
import { useSelector } from "react-redux";


//  Long id;
//  String dateDebut;
//  String dateFin;
//  EAbonnementType type;
//  long proprietaireId;

const MesAbonnements  = ()=>{

    const [abonnements, setAbonnements] = useState([]);
    const user = useSelector(state=> state.auth.user)

    useEffect(()=>{
        const fetchAbonnements = async ()=>{
            try{
                let res = await getAllAbonnmentsByIdUtilisateur(user.id)
                setAbonnements(res.data);
            }catch(error){
                console.log(error);
            }
        }
        fetchAbonnements()
    },[user.id])

    return (<div>
        {abonnements && "datafetched"}
        MesAbonnements
    </div>)
}
  
export default MesAbonnements;