import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { IMAGES } from "../../constants/images";

const AuthLayout = () => {
  const isAuthenticated = useSelector((state) => !!state.auth.user);
  const router = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      router("/");
    }
  }, [isAuthenticated, router]);
  return (
    <div className="auth-page">
      <Link to={"/"} className="logo">
        <img src={IMAGES.logo} alt="Colocky" />
      </Link>
      <Outlet />
      <div >
        <p>&copy; {new Date().getFullYear()} Colocky. All rights reserved.</p>
      </div>
    </div>
  );
};

export default AuthLayout;
