import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../../assets/css/form.css";
import BackButton from "../../components/BackButton";
import ColocItemOffre from "../../components/coloc/offreColoc/coloc-item-offre/ColocItemOffre";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import PaginationControls from "../../components/pagination/PaginationControls";
import { getOffresByUserId, marquerCommeLouee, marquerCommeDisponible } from "../../services/apis";
import LogementActions from "./logement-actions/LogementActions";

export const MesOffres = () => {
  const [logements, setLogements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(6);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const fetchLogements = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getOffresByUserId(currentPage, pageSize, user.id);
      setLogements(response.data.content);
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la récupération des logements:", error);
      setLoading(false);
    }
  }, [currentPage, pageSize, user.id]);

  useEffect(() => {
    fetchLogements();
  }, [fetchLogements]);

  const handleMarquerCommeLouee = async (logementId) => {
    try {
      await marquerCommeLouee(logementId);
      fetchLogements(); // Refresh the list after marking as louée
    } catch (error) {
      console.error("Erreur lors du marquage comme louée:", error);
    }
  };

  const handleMarquerCommeDisponible = async (logementId) => {
    try {
      await marquerCommeDisponible(logementId);
      fetchLogements(); // Refresh the list after marking as disponible
    } catch (error) {
      console.error("Erreur lors du marquage comme disponible:", error);
    }
  };

  const handleModifierOffre = (logementId) => {
    navigate('/offres/' + logementId);
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (logements.length === pageSize) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <section id="explore" className="explore" style={{ fontFamily: "Poppins" }}>
      <div className="container">
        <div>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <BackButton path="/profile" />
            <h2 style={{ marginLeft: '10px', fontSize: "25px" }}>Mes Annonces</h2>
          </div>
        </div>

        <div className="explore-content">
          <div
            className="row"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            {logements.length > 0 ? (
              logements.map((logement) => (
                <div key={logement.id} className="logement-item">
                  <ColocItemOffre
                    id={logement.id}
                    titre={logement.titre || "N/A"}
                    adresse={logement.adresse || "N/A"}
                    description={logement.description || "N/A"}
                    prix={logement.prix || "N/A"}
                    superficie={logement.superficie || 0}
                    nbrColoc={logement.nbrColoc || 0}
                    type={logement.logementType || "N/A"}
                    ville={logement.ville || {}}
                    quartier={logement.quartier || "N/A"}
                    proprietaire={logement.proprietaire || {}}
                    photos={logement.photos || []}
                    dateDisponibilite={logement.dateDisponibilite || "N/A"}
                    duree={logement.duree || "N/A"}
                    statut={logement.statut || "N/A"}
                    pageSource={"profile"}
                    createdAt={logement.createdAt}
                  />
                  <LogementActions
                    logementId={logement.id}
                    statut={logement.statut}
                    onMarquerCommeLouee={handleMarquerCommeLouee}
                    onMarquerCommeDisponible={handleMarquerCommeDisponible}
                    onModifierOffre={handleModifierOffre}
                  />
                </div>
              ))
            ) : (
              <div className="flex-row-center">
                <p>Aucun logement trouvé</p>
              </div>
            )}
          </div>

          {currentPage !== 0 && (
            <PaginationControls
              currentPage={currentPage}
              onNextPage={handleNextPage}
              onPreviousPage={handlePreviousPage}
              pageSize={pageSize}
              totalItems={logements.length}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default MesOffres;
