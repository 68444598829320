import React, { useEffect } from "react";
import { FaHome, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./SelectionModal.css";

const SelectionModal = ({
  isOpen,
  onClose,
  title,
  description,
  links,
  theme = "default",
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  if (!isOpen) return null;

  const handleLinkClick = (path) => {
    onClose();
    navigate(path);
  };

  return (
    <div className="ville-modal-overlay">
      <div className="ville-modal-content">
        <div className="ville-modal-header">
          <button className="ville-modal-close" onClick={onClose}>
            &times;
          </button>
        </div>
        <div>
          <h2 className="ville-modal-title">{title}</h2>
          <p className="ville-modal-description">{description}</p>
        </div>
        <div className="ville-modal-cards">
          {links &&
            links.map((link, index) => (
              <div key={index} className="ville-modal-card">
                <div
                  className="ville-modal-link"
                  onClick={() => handleLinkClick(link.path)}
                >
                  <div className="modal-link-flex">
                    <span>
                      {link.icon === "FaSearch" ? (
                        <FaSearch className="ville-modal-card-icon" />
                      ) : (
                        <FaHome className="ville-modal-card-icon" />
                      )}
                    </span>
                      <span className="modal-link-title">{link.title}</span>
                  </div>
                  <p
                    className="ville-modal-card-desc"
                    dangerouslySetInnerHTML={{ __html: link.description }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SelectionModal;
