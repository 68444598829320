import React from "react";
import { useNavigate } from "react-router-dom";
import { truncate } from "../../../../utils/stringUtils";
import "./index.css";

import { Building, Calendar, Clock, DollarSign } from "lucide-react";

// ListItem.jsx

const ListItem = ({ Icon, text }) => (
  <li>
    <span>
      <Icon />
    </span>
    <span>{text}</span>
  </li>
);
const ColocItemDemand = ({
  id,
  titre,
  adresse,
  description,
  budget, // anciennement "prix"
  dateDisponibilite,
  duree,
  ville,
  demandeur, // anciennement "proprietaire"
  verified,
}) => {
  const navigate = useNavigate();
  const handleItemClick = () => {
    navigate(`/utilisateur/${demandeur?.id}`);
  };

  return (
    // <div role="button" onClick={handleItemClick} className="col-md-4 col-sm-6">
    <div role="button" onClick={handleItemClick} className="">
      <div className="card-demand">
        <div className="card-header-demand">
          <div className="card-offre-person">
            <div className="card-offre-person-initial">
              {demandeur?.nom ? demandeur.nom.charAt(0).toUpperCase() : "N"}
            </div>
            <p>{demandeur?.nom || "Nom"}</p>
          </div>
        </div>
        <div>
          <h5 className="card-demande-title">{titre}</h5>
          <span className="card-demande-desc">{truncate(description, 90)}</span>
        </div>
        <ul className="card-demande-info">
          <ListItem Icon={Building} text={ville?.nom} />
          <ListItem Icon={DollarSign} text={`${budget} dh`} />
          <ListItem
            Icon={Calendar}
            text={
              dateDisponibilite === "2000-01-01"
                ? "Imediatement disponible"
                : dateDisponibilite || "Non spécifié"
            }
          />
          <ListItem Icon={Clock} text={duree || "Non spécifié"} />
        </ul>
        {verified && <div className="verified-badge">Vérifié</div>}
      </div>
    </div>
  );
};

export default ColocItemDemand;
