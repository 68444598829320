import React from "react";
import "./NotFoundPage.css";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <h1>404</h1>
      <h2>
        La page n'existe pas
      </h2>
      <p>Oops! La page que vous cherchez n'existe page</p>
      <button className="btn default">
        <Link to="/" className="home-link">
          Accueil
        </Link>
      </button>
    </div>
  );
};

export default NotFoundPage;
