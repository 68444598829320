import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ArrowLeft,
  ArrowRight,
  Calendar,
  Clock,
  Home,
  LetterText,
  ListChecks,
  MapPin,
  MapPinned,
  Users,
  Wallet,
} from "lucide-react";
import { Col } from "react-bootstrap";
import ColocList from "../../components/coloc/offreColoc/ColocList";
import { getAllOffres, getOffre } from "../../services/apis";
import ShowNumberModal from "../user/show-number-modal/ShowNumberModal";
import "./ColocDetails.css";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const defaultBg = "https://via.placeholder.com/500";

const FeatureItem = ({ title, description, IconComponent }) => (
  <Col md={3} className="feature-item">
    <div className="d-flex align-items-start">
      <div className="feature-icon-container">
        <IconComponent className="icon" />
        <h6>{title}</h6>
      </div>
      <p>{description}</p>
    </div>
  </Col>
);

function ColocDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [logement, setLogement] = useState({
    titre: "Titre non disponible",
    description: "Description non disponible",
    photos: [],
    proprietaire: { prenom: "Nom non disponible" },
    ville: { nom: "Ville non disponible" },
    quartier: "Quartier non disponible",
  });
  const [features, setFeatures] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [currentImage, setCurrentImage] = useState(defaultBg);
  const [isPhotosLoading, setIsPhotosLoading] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const loadImages = useCallback((photoData) => {
    if (!photoData || photoData.length === 0) {
      setCurrentImage(defaultBg);
      return;
    }

    const timestamp = Date.now(); // Add a timestamp to prevent caching
    const urls = photoData.map(photo => `${API_BASE_URL}/uploads/${photo.src}?t=${timestamp}`);
    setPhotos(urls);
    setCurrentImage(urls[0]);
  }, []);

  useEffect(() => {
    const fetchLogement = async () => {
      try {
        setIsPhotosLoading(true);
        const response = await getOffre(id);
        const logementData = response.data;
        setLogement(logementData);
        loadImages(logementData.photos);

        if (!logementData.verified && logementData.proprietaire.id !== user?.id) {
          navigate("/404");
          return;
        }

        const dynamicFeatures = [
          {
            title: "Ville",
            description: logementData.ville.nom || "Non spécifiée",
            IconComponent: MapPin,
          },
          {
            title: "Quartier",
            description: logementData.quartier || "Non spécifié",
            IconComponent: MapPinned,
          },
          {
            title: "Durée",
            description: logementData.duree ? `${logementData.duree} mois` : "Non spécifiée",
            IconComponent: Clock,
          },
          {
            title: "Places disponibles",
            description: `${logementData.nbrColoc || 0} places`,
            IconComponent: Users,
          },
          {
            title: "Budget",
            description: `${logementData.prix} dh`,
            IconComponent: Wallet,
          },
          {
            title: "Disponibilité",
            description: logementData.dateDisponibilite === '2000-01-01' ? "Immédiatement" : logementData.dateDisponibilite || "Immédiatement",
            IconComponent: Calendar,
          },
          {
            title: "Type de logement",
            description: logementData.logementType || "Non spécifié",
            IconComponent: Home,
          },
        ];
        setFeatures(dynamicFeatures);
      } catch (error) {
        console.error("Erreur lors de la récupération du logement:", error);
        navigate("/404");
      } finally {
        setIsPhotosLoading(false);
      }
    };

    fetchLogement();
    window.scrollTo(0, 0);
  }, [id, navigate, user?.id, loadImages]);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      (prevIndex + 1) % photos.length
    );
  };

  useEffect(() => {
    if (photos.length > 0) {
      setCurrentImage(photos[currentImageIndex]);
    }
  }, [currentImageIndex, photos]);

  const handleDemandeClick = () => {
    if (user) {
      setShowPopup(true);
    } else {
      navigate("/auth/login");
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleVoirPlus = () => {
    navigate("/coloc/offres");
  };

  const getAvatarLetter = (prenom) =>
    prenom ? prenom.charAt(0).toUpperCase() : "";

  if (!logement) {
    return <div>Chargement...</div>;
  }

  return (
    <section className="container coloc-section">
      <div className="coloc-details-header">
        <h1 className="coloc-details-title">{logement.titre}</h1>
        <p>{logement.ville.nom}, {logement.quartier}</p>
      </div>

      <div className="row second-row">
        <div className="col-md-8">
          {isPhotosLoading ? (
            "Chargement des images..."
          ) : (
            <div className="position-relative">
              <div className="image-container">
                <div className="current-image">
                  <img
                    src={currentImage}
                    alt={logement.titre}
                    loading="lazy"
                    onError={(e) => {
                      console.error("Image load error:", e);
                      e.target.src = defaultBg;
                    }}
                  />
                </div>
                {photos.length > 1 && (
                  <>
                    <button onClick={handlePrevImage} className="nav-button nav-button-left">
                      <ArrowLeft />
                    </button>
                    <button onClick={handleNextImage} className="nav-button nav-button-right">
                      <ArrowRight />
                    </button>
                  </>
                )}
              </div>
              <div className="thumbnail-container">
                {photos.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    onClick={() => setCurrentImageIndex(index)}
                    alt={`${logement.titre} ${index + 1}`}
                    className={`thumbnail ${index === currentImageIndex ? 'active' : ''}`}
                    onError={(e) => {
                      console.error("Thumbnail load error:", e);
                      e.target.src = defaultBg;
                    }}
                  />
                ))}
              </div>
            </div>
          )}
          {/* <div className="mt-4 description-section">
            <h3>Description</h3>
            <p>{logement?.description}</p>
          </div> */}
          {/* {FeaturesList} */}
          {/* <hr /> */}
        </div>
        <div className="col-md-4">
          <div className="card card-hosted-by">
            <div className="centered-content">
              <div className="card-offre-person">
                <div
                  className="card-offre-person-initial"
                  style={{
                    cursor: logement?.proprietaire?.id ? "pointer" : "default",
                  }}
                  onClick={() =>
                    logement?.proprietaire?.id &&
                    navigate(`/utilisateur/${logement?.proprietaire?.id}`)
                  }
                >
                  {getAvatarLetter(logement.proprietaire.prenom)}
                </div>
              </div>
              <h3>{logement.proprietaire.prenom}</h3>
            </div>
            <button onClick={handleDemandeClick} className="btn btn-block default">
              Afficher le numéro
            </button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 coloc-details-section">
          <h3>
            <LetterText />
            Description
          </h3>
          <p>{logement.description}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 coloc-details-section">
          <h3>
            <ListChecks />
            Features
          </h3>
          {features.map((feature, index) => (
            <FeatureItem
              key={index}
              title={feature.title}
              description={feature.description}
              IconComponent={feature.IconComponent}
            />
          ))}
        </div>
      </div>

      <div className="row">
        <div className="coloc-similar-wrapper">
          <div className="coloc-similar-header">
            <h3>Autres offres similaires</h3>
            <div onClick={handleVoirPlus} style={{ cursor: 'pointer' }}>
              <Link to="#">
                Voir plus
                <ArrowRight className="icon" />
              </Link>
            </div>
          </div>
          <div>
            <ColocList
              fromPublic={false}
              getLogementsApi={getAllOffres}
              title="Offres"
              subtitle="Explorer d'autres Offres de colocations similaires"
              selectedId={id}
            />
          </div>
        </div>
      </div>

      {showPopup && (
        <ShowNumberModal
          isOpen={showPopup}
          onClose={handleClosePopup}
          description="Vérifiez bien le colocataire potentiel avant de partager des informations ou de faire un paiement. Rencontrez-le d'abord en personne."
          sousDescription="Appelez pour organiser une rencontre."
          title="Important !"
          phoneNumber={logement?.proprietaire?.telephone}
          idProp={logement?.proprietaire?.id}
        />
      )}
    </section>
  );
}

export default ColocDetails;