import React from 'react';

const Modal = ({ show, handleClose, children }) => {
    return (
        <div className={`modal ${show ? 'show' : ''}`} onClick={handleClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <h4 className="modal-title">Complete votre Profile</h4>
                    <button className="close" onClick={handleClose}>&times;</button>
                </div>
                <div className="modal-body">
                    {children}
                </div>
                <div className="modal-footer">
                    <button className="btn btn-danger" onClick={handleClose}>Fermer</button>
                </div>
            </div>
        </div>
    );
};
export default Modal;
