export const timeAgo = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);
    
    if (diffInSeconds < 60) {
      return `il y a ${diffInSeconds} secondes`;
    } else if (diffInSeconds < 3600) {
      return `il y a ${Math.floor(diffInSeconds / 60)} minutes`;
    } else if (diffInSeconds < 86400) {
      return `il y a ${Math.floor(diffInSeconds / 3600)} heures`;
    } else if (diffInSeconds < 2592000) {
      return `il y a ${Math.floor(diffInSeconds / 86400)} jours`;
    } else if (diffInSeconds < 31536000) {
      return `il y a ${Math.floor(diffInSeconds / 2592000)} mois`;
    } else {
      return `il y a ${Math.floor(diffInSeconds / 31536000)} ans`;
    }
  };
  