import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDemandesByVille } from "../../../services/apis";
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";
import PaginationControls from "../../pagination/PaginationControls";
import { useError } from "../../providers/ErrorProvider";
import CityFilterBar from "../city-filter-bar/CityFilterBar";
import ColocItemDemand from "./coloc-item-demand/ColocItemDemand";

const DemandeListWithFilter = ({ getLogementsApi, title, subtitle }) => {
  const { villeId } = useParams(); // Used for initial filtering if villeId is provided in the URL
  const [logements, setLogements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(12);
  const [selectedVilleId, setSelectedVilleId] = useState(villeId || null);
  const { showError } = useError();

  const villes = useSelector((state) => state.generalData.villeColocInfo);

  useEffect(() => {
    const fetchLogements = async () => {
      try {
        setLoading(true);
        const response = selectedVilleId
          ? await getDemandesByVille(currentPage, pageSize, selectedVilleId)
          : await getLogementsApi(currentPage, pageSize);
        setLogements(response.data.content);
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des logements:", error);
        setLoading(false);
        showError("Erreur lors de la récupération des logements.");
      }
    };

    fetchLogements();
  }, [getLogementsApi, selectedVilleId, currentPage, pageSize, showError]);

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (logements.length === pageSize) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleCityFilterChange = (villeId) => {
    setSelectedVilleId(villeId);
    setCurrentPage(0); // Reset to the first page when filtering
  };

  const handleResetFilter = () => {
    setSelectedVilleId(null);
    setCurrentPage(0); // Reset to the first page when resetting filter
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <section
      id="explore"
      className="explore"
      style={{
        minHeight: "100vh",
        fontFamily: "Poppins",
      }}
    >
      <div className="filter-container">
        <div className="row filter-container-header">
          <div className="col-md-12">
            <h2>{title}</h2>
            <p>{subtitle}</p>
          </div>
        </div>
        {/* </div> */}

        {/* <div className="filter-container"> */}
        <div className="row">
          {/* Sidebar for Filtering by City */}
          <div className="col-md-3 left-filter">
            <CityFilterBar
              villes={villes}
              selectedVilleId={selectedVilleId}
              onCityFilterChange={handleCityFilterChange}
              onResetFilter={handleResetFilter}
            />
          </div>
          {/* Main Content */}
          <div className="col-md-9">
            <div className="explore-grid-demande-filter">
              {logements.length > 0 ? (
                logements.map((logement) => (
                  <ColocItemDemand
                    key={logement.id}
                    id={logement.id}
                    titre={logement.titre}
                    adresse={logement.adresse}
                    description={logement.description}
                    budget={logement.budget}
                    dateDisponibilite={logement.dateDisponibilite}
                    duree={logement.duree}
                    ville={logement.ville}
                    demandeur={logement.demandeur}
                  />
                ))
              ) : (
                <div className="flex-row-center">
                  <p>Aucun logement trouvé</p>
                </div>
              )}
            </div>

            {/* Pagination Controls */}
            <PaginationControls
              currentPage={currentPage}
              pageSize={pageSize}
              totalItems={logements.length}
              onPreviousPage={handlePreviousPage}
              onNextPage={handleNextPage}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DemandeListWithFilter;
