import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';

function StepTwoPreferencesForm({ formData, handleChange, handleCityChange, previousStep, nextStep, villes }) {
  const validateAndProceed = (e) => {
    e.preventDefault();

    const { villeId, budget, description, quartier, dateDisponibilite, specificDate, duree, titre } = formData;

    // Title validation
    if (!titre) {
      alert('Veuillez entrer un titre pour l\'offre.');
      return;
    }

    // Description validation
    if (!description) {
      alert('Veuillez fournir une description de l\'offre.');
      return;
    }

    // City validation
    if (!villeId) {
      alert('Veuillez sélectionner une ville.');
      return;
    }

    // Neighborhood (quartier) validation
    if (!quartier) {
      alert('Veuillez entrer le quartier de la colocation.');
      return;
    }

    // Budget validation
    if (!budget || budget <= 0) {
      alert('Veuillez entrer un budget valide.');
      return;
    }

    // Disponibilité validation
    if (!dateDisponibilite) {
      alert('Veuillez sélectionner une option de disponibilité.');
      return;
    }

    // Specific date validation if selected
    if (dateDisponibilite === 'SPECIFIC' && !specificDate) {
      alert('Veuillez spécifier une date de disponibilité.');
      return;
    }

    // Duration validation
    if (!duree || duree <= 0) {
      alert('Veuillez entrer une durée valide en mois.');
      return;
    }

    nextStep();
  };

  return (
    <Container className='formContainer'>
      <h2 className='formTitle'>Préférences de Colocation</h2>
      <Row>
        <Col md={6}>
          <Form onSubmit={validateAndProceed}>
            <Form.Group className="mb-3 formGroup">
              <Form.Label>Titre</Form.Label>
              <Form.Control
                type="text"
                name="titre"
                value={formData.titre}
                onChange={handleChange}
                placeholder="Entrez le titre de l'offre"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 formGroup">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Décrivez votre offre"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 formGroup">
              <Form.Label>Ville</Form.Label>
              <Form.Control
                as="select"
                name="villeId"
                value={formData.villeId}
                onChange={handleCityChange}
                required
              >
                <option value="">Sélectionnez une ville</option>
                {villes.map((ville) => (
                  <option key={ville.id} value={ville.id}>{ville.nom}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 formGroup">
              <Form.Label>Quartier</Form.Label>
              <Form.Control
                type="text"
                name="quartier"
                value={formData.quartier}
                onChange={handleChange}
                placeholder="Entrez le quartier"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 formGroup">
              <Form.Label>Budget</Form.Label>
              <Form.Control
                type="number"
                name="budget"
                value={formData.budget}
                onChange={handleChange}
                placeholder="Entrez votre budget"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 formGroup">
              <Form.Label>Disponibilité</Form.Label>
              <Form.Check
                type="radio"
                label="Immédiatement disponible"
                name="dateDisponibilite"
                value="IMMEDIATE"
                checked={formData.dateDisponibilite === "IMMEDIATE"}
                onChange={handleChange}
              />
              <Form.Check
                type="radio"
                label="Disponible à partir d'une date spécifique"
                name="dateDisponibilite"
                value="SPECIFIC"
                checked={formData.dateDisponibilite === "SPECIFIC"}
                onChange={handleChange}
              />
            </Form.Group>
            {formData.dateDisponibilite === "SPECIFIC" && (
              <Form.Group className="mb-3 formGroup">
                <Form.Label>Date souhaitée</Form.Label>
                <Form.Control
                  type="date"
                  name="specificDate"
                  value={formData.specificDate}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            )}
            <Form.Group className="mb-3 formGroup">
              <Form.Label>Durée (mois)</Form.Label>
              <Form.Control
                type="number"
                name="duree"
                value={formData.duree}
                onChange={handleChange}
                placeholder="Durée de la colocation (en mois)"
                required
              />
            </Form.Group>
            <div className="formButtons">
              <button className="previous" onClick={previousStep}>Précédent</button>
              <button className="next default" type="button" onClick={validateAndProceed}>Suivant</button>

            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default StepTwoPreferencesForm;
