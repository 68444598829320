import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || 'http://localhost:8081/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  token: localStorage.getItem('token') || null,
  tokenType: localStorage.getItem('tokenType') || null,
  status: 'idle',
  error: null,
  message: null,
  subscribed: localStorage.getItem('subscribed') === 'true' || false,
  demandeFilled: localStorage.getItem('demandeFilled') === 'true' || false,
  profileFilled: localStorage.getItem('profileFilled') === 'true' || false,
};

// Capture and return error messages in the signIn async action
export const signIn = createAsyncThunk(
  'auth/signIn',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await instance.post('/auth/signin', credentials);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        return rejectWithValue("email et/ou mot de passe incorrect(s)");
      }
      return rejectWithValue('An unknown error occurred during sign-in.');
    }
  }
);

// Capture and return error messages in the signUp async action
export const signUp = createAsyncThunk(
  'auth/signUp',
  async (userInfo, { rejectWithValue }) => {
    try {
      const response = await instance.post('/auth/signup', userInfo);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue('An unknown error occurred during sign-up.');
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.tokenType = null;
      state.subscribed = false;
      state.demandeFilled = false;
      state.profileFilled = false; 
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('tokenType');
      localStorage.removeItem('subscribed');
      localStorage.removeItem('demandeFilled');
      localStorage.removeItem('profileFilled');
    },
    clearMessage: (state) => {
      state.message = null;
    },
    setProfileFilled: (state, action) => {
      state.profileFilled = action.payload;
      localStorage.setItem('profileFilled', action.payload);
    },
    setDemandeFilled: (state, action) => {
      state.demandeFilled = action.payload;
      localStorage.setItem('demandeFilled', action.payload);
    },
    resetAuthState: (state) => {
      state.status = 'idle';
      state.error = null;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = {
          id: action.payload.id,
          username: action.payload.username,
          email: action.payload.email,
          roles: action.payload.roles,
          subscribed: action.payload.subscribed,
        };
        state.token = action.payload.accessToken;
        state.tokenType = action.payload.tokenType;
        state.message = 'Login successful!';
        state.error = null; // Clear any previous error
        localStorage.setItem('user', JSON.stringify(state.user));
        localStorage.setItem('token', action.payload.accessToken);
        localStorage.setItem('tokenType', action.payload.tokenType);
        localStorage.setItem('subscribed', action.payload.subscribed);

        // Set demandeFilled based on response or fallback to false
        const demandeFilled = action.payload.demandeFilled || false; 
        state.demandeFilled = demandeFilled;
        localStorage.setItem('demandeFilled', demandeFilled);

        // Set profileFilled based on response or fallback to false
        const profileFilled = action.payload.profileFilled || false; 
        state.profileFilled = profileFilled;
        localStorage.setItem('profileFilled', profileFilled);
      })
      .addCase(signIn.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(signUp.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.message = 'Registration successful! Please sign in.';
        state.error = null; // Clear any previous error
      })
      .addCase(signUp.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      });
  },
});

export const { logout, clearMessage, resetAuthState ,setProfileFilled,setDemandeFilled} = authSlice.actions;

export default authSlice.reducer;
