import { FileImage, HousePlus, ListCheck, User } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../../../assets/css/form.css";
import { createOffre, getAllVilles } from "../../../../services/apis";
import StepOneProprietaireForm from "./StepOneProprietaireForm";
import StepThreeImageUploadForm from "./StepThreeImageUploadForm";
import StepTwoLogementForm from "./StepTwoLogementForm";
import Toast from '../../../toast/Toast.jsx';

const steps = [
  {
    step: 1,
    title: "Propriétaire",
    svgPath: <User />,
  },
  {
    step: 2,
    title: "Logement",
    svgPath: <HousePlus />,
  },
  {
    step: 3,
    title: "Images",
    svgPath: <FileImage />,
  },
  {
    step: 4,
    title: "Confirmation",
    svgPath: <ListCheck />,
  },
];

function MultiStepForm() {
  const [step, setStep] = useState(1);
  const [error, setError] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({
    titre: "",
    description: "",
    villeId: "",
    quartier: "",
    superficie: null,
    nbrColoc: null,
    duree:null,
    logementType: "",
    prix: "",
    photos: [],
    dateDisponibilite: "IMMEDIATE",
    proprietaireId: user?.id || "",
  });
  const [additionalData, setAdditionalData] = useState({
    villeName: "",
    proprietaireNom: user?.username || "",
    proprietaireEmail: user?.email || "",
  });
  const [villes, setVilles] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const typesDeLogement = useSelector(
    (state) => state.generalData.logementTypes
  );
  console.log("typesDeLogement:", typesDeLogement);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllVilles();
        setVilles(response.data.content);
      } catch (error) {
        console.error("Error fetching villes", error);
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "superficie" || name === "nbrColoc" || name === "prix" || name === "duree"
          ? parseFloat(value)
          : value,
    }));
  };

  const handleCityChange = (e) => {
    const selectedVilleId = Number(e.target.value);
    const selectedVille = villes.find((ville) => ville.id === selectedVilleId);
    if (selectedVille) {
      setFormData((prevData) => ({
        ...prevData,
        villeId: selectedVilleId,
      }));
      setAdditionalData((prevData) => ({
        ...prevData,
        villeName: selectedVille.nom,
      }));
    }
  };

  const handleImageChange = (filenames) => {
    setFormData({
      ...formData,
      photos: filenames,
    });
  };

  const handleImages = (images) => {
    setImages(images);
  };

  const nextStep = () => {
    if (step === 1 && !formData.proprietaireId) {
      setError("Veuillez renseigner les informations du propriétaire.");
      return;
    }
    if (step === 3 && !validateFormData()) {
      setError("Veuillez renseigner toutes les informations du logement.");
      return;
    }
    setStep(step + 1);
  };
    const previousStep = () => setStep(step - 1);

  const validateFormData = () => {
    const { titre, description, villeId, quartier, superficie, nbrColoc, duree, logementType, prix } = formData;
    return (
      titre &&
      description &&
      villeId &&
      quartier &&
      superficie > 0 &&
      nbrColoc > 0 &&
      duree > 0 &&
      logementType &&
      prix > 0
    );
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateFormData()) {
      setError("Tous les champs sont requis. Veuillez compléter toutes les informations.");
      return;
    }
    
    try {
      const submissionData = {
        ...formData,
        proprietaireId: parseInt(formData.proprietaireId),
        villeId: parseInt(formData.villeId),
        superficie: parseFloat(formData.superficie),
        nbrColoc: parseFloat(formData.nbrColoc),
        duree: parseFloat(formData.duree),
        prix: parseFloat(formData.prix),
        dateDisponibilite:
          formData.dateDisponibilite === "IMMEDIATE"
            ? "2000-01-01"
            : formData.dateDisponibilite,
      };
      await createOffre(submissionData);
      setShowToast(true);

      // Navigate to another page after 3 seconds (3000 ms)
      setTimeout(() => {
        navigate('/'); // Replace with your desired path
      }, 4000);
    } catch (error) {
      console.error("Error creating logement", error);
      setError(
        error.response?.data?.message ||
        "Une erreur s'est produite lors de la soumission du logement"
      );
    }
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };
  
  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <StepOneProprietaireForm
            formData={additionalData}
            nextStep={nextStep}
          />
        );
      case 2:
        return (
          <StepTwoLogementForm
            formData={formData}
            handleChange={handleChange}
            handleCityChange={handleCityChange}
            previousStep={previousStep}
            nextStep={nextStep}
            typesDeLogement={typesDeLogement}
            villes={villes}
          />
        );
      case 3:
        return (
          <StepThreeImageUploadForm
            formData={formData}
            previousStep={previousStep}
            nextStep={nextStep}
            handleImageChange={handleImageChange}
            handleImages={handleImages}
            images={images}
          />
        );
      case 4:
        return (
          <Container className="formContainer">
            <h2 className="formTitle">Confirmez et soumettez</h2>
            <Form>
              <Form.Group className="formGroup">
                <Form.Label>Nom du propriétaire</Form.Label>
                <Form.Control
                  value={additionalData.proprietaireNom}
                  readOnly
                  type="text"
                  name="username"
                />
              </Form.Group>
              <Form.Group className="formGroup">
                <Form.Label>Email du propriétaire</Form.Label>
                <Form.Control
                  value={additionalData.proprietaireEmail}
                  readOnly
                  type="email"
                  name="email"
                />
              </Form.Group>
              <Form.Group className="formGroup">
                <Form.Label>Titre</Form.Label>
                <Form.Control
                  value={formData.titre}
                  readOnly
                  type="text"
                  name="titre"
                />
              </Form.Group>
              <Form.Group className="formGroup">
                <Form.Label>Adresse</Form.Label>
                <Form.Control
                  value={formData.quartier}
                  readOnly
                  type="text"
                  name="quartier"
                />
              </Form.Group>
              <Form.Group className="formGroup">
                <Form.Label>Prix</Form.Label>
                <Form.Control
                  value={formData.prix}
                  readOnly
                  type="number"
                  name="prix"
                />
              </Form.Group>
              <Form.Group className="formGroup">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  value={formData.description}
                  readOnly
                  as="textarea"
                  name="description"
                />
              </Form.Group>
              <Form.Group className="formGroup">
                <Form.Label>Superficie</Form.Label>
                <Form.Control
                  value={formData.superficie}
                  readOnly
                  type="number"
                  name="superficie"
                />
              </Form.Group>
              <Form.Group className="formGroup">
                <Form.Label>Nombre de colocataires</Form.Label>
                <Form.Control
                  value={formData.nbrColoc}
                  readOnly
                  type="number"
                  name="nbrColoc"
                />
              </Form.Group>
              <Form.Group className="formGroup">
                <Form.Label>Type de logement</Form.Label>
                <Form.Control
                  value={formData.logementType}
                  readOnly
                  type="text"
                  name="logementType"
                />
              </Form.Group>
              <Form.Group className="formGroup">
                <Form.Label>Disponibilité</Form.Label>
                <Form.Control
                  value={
                    formData.dateDisponibilite === "IMMEDIATE"
                      ? "Immédiatement disponible"
                      : `Disponible à partir du ${formData.dateDisponibilite}`
                  }
                  readOnly
                  type="text"
                />
              </Form.Group>
              <Form.Group className="formGroup">
                <Form.Label>Durée</Form.Label>
                <Form.Control
                  value={formData.duree}
                  readOnly
                  type="number"
                  name="duree"
                />
              </Form.Group>
              <Form.Group className="formGroup">
                <Form.Label>Ville</Form.Label>
                <Form.Control
                  value={additionalData.villeName}
                  readOnly
                  type="text"
                  name="villeName"
                />
              </Form.Group>
              <Form.Group className="formGroup">
                <Form.Label>Images</Form.Label>
                <div className="image-previews">
                  {images.map((image, index) => (
                    <div key={index} className="image-preview">
                      <img
                        src={image.preview}
                        alt={`preview ${index}`}
                        width={100}
                        height={100}
                      />
                    </div>
                  ))}
                </div>
              </Form.Group>
            </Form>
            <div className="nice-form-group">
              <button className="default" type="button" onClick={previousStep}>
                Précédent
              </button>
              <button className="default" type="button" onClick={handleSubmit}>
                Soumettre
              </button>

            </div>
          </Container>
        );
      default:
        return (
          <div>
            <h2>
              <u>Merci pour votre soumission</u>
            </h2>
            <p>
              Votre logement a été soumis avec succès et sera envoyé à
              l'administrateur pour vérification. Une fois vérifié, il sera
              publié.
            </p>
            <div className="nice-form-group">
              <button
                className="default"
                type="button"
                onClick={() => navigate("/")}
              >
                Retour à l'accueil
              </button>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="coloc-form">
      <div className="demo-page">
      <div className="demo-page-navigation">
          <nav>
            <ul>
              {steps.map(({ step: stepNum, title, svgPath }) => (
                <li key={stepNum}>
                  <a
                    href={`#step${stepNum}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setStep(stepNum);
                    }}
                    className={step === stepNum ? "selected" : ""}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      {svgPath}
                    </svg>
                    <span className="step-title">{title}</span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        
        <div className="demo-page-content">
        <h2 style={{ textAlign: 'center', fontWeight: 'bold', color: '#ff545a' }}>
        Ajouter une offre
        </h2>
          {error && <div className="alert alert-danger">{error}</div>}
          {renderStepContent()}
          {showToast && (
        <Toast
          title="Offre Créé"
          desc="Votre offre a été soumise avec succès. Dès que l'administrateur la valide, elle sera affichée."
          delay={4000}
          onClose={handleCloseToast}
        />
      )}
        </div>
      </div>
    </div>
  );
}

export default MultiStepForm;
