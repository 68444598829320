import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../state/slices/authSlice';
import colocReducer from '../state/slices/colocSlice';
import generalDataReducer from '../state/slices/generalDataSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    coloc: colocReducer,
    generalData: generalDataReducer,
  },
});

export default store;