import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useError } from "../../providers/ErrorProvider";
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";
import ColocItemDemand from "./coloc-item-demand/ColocItemDemand";
import { FaArrowRight } from "react-icons/fa";

const ColocList = ({ getLogementsApi, title, LinktoMore }) => {
  const [logements, setLogements] = useState([]);
  const [loading, setLoading] = useState(true);
  const { showError } = useError();

  useEffect(() => {
    const fetchLogements = async () => {
      try {
        setLoading(true);
        const response = await getLogementsApi();
        setLogements(response.data.content);
        setLoading(false);
      } catch (error) {
        // showError("Erreur lors de la récupération des logements : " + error.message);
        console.error("Erreur lors de la récupération des logements:", error);
        setLoading(false);
      }
    };

    fetchLogements();
  }, [getLogementsApi, showError]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <section id="explore" className="explore">
      <div className="container">
        <div className="section-header">
          <h2>{title}</h2>
          <p>Explorer les colocations récemment publiées</p>
        </div>

        <div className="explore-content">
          <div
            className="row explore-grid"
            // style={
            //   {
            // display: "flex",
            // flexDirection: "row",
            // flexWrap: "wrap",
            // gap: title === "Demandes" ? "0rem" : "10px",
            //   }
            // }
          >
            {logements.length > 0 &&
              logements.map((logement) => (
                <div className="col-md-4 col-sm-6">
                  <ColocItemDemand
                    key={logement.id}
                    id={logement.id}
                    titre={logement.titre}
                    adresse={logement.adresse}
                    description={logement.description}
                    budget={logement.budget}
                    dateDisponibilite={logement.dateDisponibilite}
                    duree={logement.duree}
                    ville={logement.ville}
                    demandeur={logement.demandeur}
                  />
                </div>
              ))}
          </div>
          {logements.length === 0 ? (
            <div className="flex-row-center">
              <p>Aucun logement trouvé</p>
            </div>
          ) : (
            <div className="flex-row-center">
              {LinktoMore && (
                <Link
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    color: "#ff545a",
                    textDecoration: "none",
                    padding: "0.5rem 1rem",
                    border: "1px solid #ff545a",
                    marginTop: "2rem",
                  }}
                  to={LinktoMore}
                >
                  Voir plus <FaArrowRight />
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ColocList;
