import React from 'react';
import { Button, Form } from 'react-bootstrap';

function ImageUploadForm({ images, onFileChange, onDelete, error }) {
  return (
    <>
      <Form.Group className="formGroup">
        <Form.Label>Images du logement</Form.Label>
        <Form.Control 
          type="file" 
          name="images" 
          multiple 
          onChange={onFileChange} 
          label="Ajouter des fichiers" 
        />
        
        {error && (
          <div style={{ color: 'red', marginTop: '10px', fontWeight: 'bold' }}>
            {error}
          </div>
        )}

        <div className="nice-form-group image-previews">
          {images.map((image, index) => (
            <div key={index} className="image-preview">
              <img src={image.preview} alt={`Aperçu ${index}`} width={100} height={100} />
              <div className={`status ${image.status}`}>
                {image.status === 'uploading' && 'Téléchargement...'}
                {image.status === 'ready' && 'Prêt'}
                {image.status === 'error' && 'Erreur'}
              </div>
              <Button type="button" variant="primary" onClick={() => onDelete(image)}>
                Supprimer
              </Button>
            </div>
          ))}
        </div>
      </Form.Group>
    </>
  );
}

export default ImageUploadForm;
