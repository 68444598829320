import React, { useEffect, useState } from "react";
import { getAllValidAvis } from "../../services/apis";
import { useNavigate } from "react-router-dom";
import "./index.css";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState("prev");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  // const fallbackTestimonials = Array.from({ length: 6 }, (_, i) => ({
  //   name: `User ${i + 1}`,
  //   role: "Web Developer",
  //   text: `Lorem ipsum dolor sit amet consectetur, adipisicing elit.
  //          Expedita velit labore suscipit distinctio, officiis deserunt rem
  //          blanditiis ducimus. Voluptate quaerat assumenda qui veniam
  //          facilis doloribus maiores impedit ducimus cum accusamus.`,
  //   note: Math.floor(Math.random() * 5) + 1,
  // }));

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    setIsLoading(true);
    getAllValidAvis(0, 6)
      .then((response) => {
        const reviewsFromApi = response.data.content.map((review) => ({
          name:
            review.auteur.nom ||
            review.auteur.prenom ||
            review.auteur.email.split("@")[0],
          text: review.commentaire,
          note: review.note,
        }));
        setReviews(reviewsFromApi.length > 0 ? reviewsFromApi : []);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the reviews!", error);
        // setReviews(fallbackTestimonials);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => window.removeEventListener("resize", updateScreenWidth);
  }, []);

  const goToPrevious = () => {
    setDirection("prev");
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : reviews.length - 1
    );
  };

  const goToNext = () => {
    setDirection("next");
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDirection("");
    }, 500);
    return () => clearTimeout(timer);
  }, [currentIndex, direction]);

  useEffect(() => {
    if (reviews.length > 0) {
      const interval = setInterval(goToNext, 5000);
      return () => clearInterval(interval);
    }
  });

  const renderTestimonial = (index) => {
    if (isLoading || reviews.length === 0) {
      return <div>Loading...</div>;
    }

    const testimonial = reviews[index];
    if (!testimonial) {
      return <div>No testimonial available</div>;
    }

    const firstLetter = testimonial.name
      ? testimonial.name.charAt(0).toUpperCase()
      : "?";

    return (
      <div className="testimonial-card" key={index}>
        <div className="testimonial-content">
          <div className="user-info">
            <div className="user-image">{firstLetter}</div>
            <div className="user-details">
              <div className="user-name">{testimonial.name}</div>
              <div className="user-role">{testimonial.role}</div>
              <div className="user-rating">{"★".repeat(testimonial.note)}</div>
            </div>
          </div>
          <p className="testimonial-text">{testimonial.text}</p>
          <div className="quote-mark">"</div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <section id="reviews" className="reviews">
      <div className="section-header">
        <h2>Avis des utilisateurs</h2>
        <p>Votre avis nous intéresse</p>
      </div>
      {reviews.length > 0 ? (
        <div className="testimonials">
          <button onClick={goToPrevious} className="carousel-btn left-btn">
            {"<"}
          </button>
          <div className={`testimonial-container ${direction}`}>
            {renderTestimonial(currentIndex)}
            {screenWidth > 768 &&
              renderTestimonial((currentIndex + 1) % reviews.length)}
          </div>
          <button onClick={goToNext} className="carousel-btn right-btn">
            {">"}
          </button>
        </div>
      ) : (
        <div className="flex-center">No reviews available at the moment.</div>
      )}
        <div className="flex-center">
          <button
            className="btn btn-primary navbar-btn"
            onClick={() => navigate("/add-review")}
          >
            Ajouter votre Avis
          </button>
        </div>
    </section>
  );
};

export default Reviews;
