import React from 'react';
import ColocHomeList from '../components/coloc/offreColoc/ColocHomeList';
import DemandeHomeList from '../components/coloc/demandeColoc/DemandeHomeList';
import Herosection from '../components/Herosection';
import HowItWork from '../components/HowItWorks';
import Reviews from '../components/reviews/Reviews';
// import Statistics from '../components/Statistics';
import Subscription from '../components/Subscription';
import ListVilles from '../components/Ville/ListVilles';
import { getAllDemandes, getAllOffres } from "../services/apis";
import BlogSection from '../components/blog/BlogSection';

const HomePage = () => {
  return (
    <div className="home-page"
      style={{
        fontFamily: 'Poppins, sans-serif',
      }}
    >
      <Herosection />
      <ListVilles />
      <HowItWork />
      <ColocHomeList
        LinktoMore='/coloc/offres'
        getLogementsApi={()=>getAllOffres(0,6)}
        title="Offres"
      />
      <DemandeHomeList
        LinktoMore='/coloc/demandes'
        getLogementsApi={()=>getAllDemandes(0,6)}
        title="Demandes"
      />
      <BlogSection/>
      <Reviews />
      <Subscription />
    </div>
  );
};

export default HomePage;
