import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ColocList from "../../components/coloc/offreColoc/ColocList";
import { getOffresByUserId } from "../../services/apis";
import BackButton from "../../components/BackButton";
import { useError } from "../../components/providers/ErrorProvider";

const Annonces = () => {
  const [activeTab, setActiveTab] = useState("offre");
  const [logements, setLogements] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const { showError } = useError();

  useEffect(() => {
    const fetchLogements = async () => {
      try {
        const response = await getOffresByUserId(user.id);
        setLogements(response.data);
      } catch (error) {
        showError("Erreur lors de la récupération des logements : " + error.message);
        console.error("Erreur lors de la récupération des logements:", error);
      }
    };

    fetchLogements();
  });

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const filteredLogements = logements.filter(
    (logement) => logement?.type === activeTab
  );

  return (
    <div className="annonces-page">
      <div className="annonces-container">
        <div className="annonces-content">

          <div className="annonces-tabs">
            <div className="annonces-tab-item">
              <button
                className={`annonces-tab-link ${activeTab === "offre" ? "active" : ""
                  }`}
                onClick={() => handleTabChange("offre")}
              >
                Offres
              </button>
            </div>
            <div className="annonces-tab-item">
              <button
                className={`annonces-tab-link ${activeTab === "demande" ? "active" : ""
                  }`}
                onClick={() => handleTabChange("demande")}
              >
                Demandes
              </button>
            </div>
          </div>

          <div className="annonces-tab-content">
            <div className="annonces-tab-pane">
              <BackButton path="/profile" />
              <div
                style={{
                  padding: "10px ",
                  display: "flex",
                  flexDirection: "row",
                  // backgroundColor: "green",
                  flexWrap: "wrap",
                  gap: activeTab === "demande" ? "0rem" : "10px",
                }}
              >
                <ColocList
                  logements={filteredLogements}
                  // getLogements={() => Promise.resolve({ data: filteredLogements })}
                  title={
                    activeTab === "offre"
                      ? "Offres"
                      : "Demandes"
                  }
                  fromPublic={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Annonces;
