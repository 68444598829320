import React from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import './../../../../assets/css/custom-form.css';

const schema = yup.object().shape({
  proprietaireNom: yup.string().required('Le nom du propriétaire est requis'),
  proprietaireEmail: yup.string().email('Email invalide').required('L\'email du propriétaire est requis'),
});

function StepOneProprietaireForm({ formData, nextStep }) {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      proprietaireNom: formData.proprietaireNom,
      proprietaireEmail: formData.proprietaireEmail,
    }
  });

  const onSubmit = (data) => {
    nextStep();
  };

  return (
    <Container className="formContainer">
      <h2 className='formTitle'>Confirmez vos informations</h2>
      <Row>
        <Col md={{ span: 12, offset: 3 }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3 formGroup">
              <Form.Label>Nom du propriétaire</Form.Label>
              <Form.Control
                type="text"
                {...register('proprietaireNom')}
                isInvalid={!!errors.proprietaireNom}
              />
              <Form.Control.Feedback className="invalid" type="invalid">
                {errors.proprietaireNom?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="formGroup">
              <Form.Label>Email du propriétaire</Form.Label>
              <Form.Control
                type="email"
                {...register('proprietaireEmail')}
                isInvalid={!!errors.proprietaireEmail}
              />
              <Form.Control.Feedback className="invalid" type="invalid">
                {errors.proprietaireEmail?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              className='default'
              variant=''
              type="submit"
            >
              Suivant
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default StepOneProprietaireForm;