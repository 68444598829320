import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from 'react-icons/fa';

const BackButton = ({ path }) => {
  const navigate = useNavigate();

  const handleProfileBack = () => {
    navigate(path);
  }

  return (
    <button onClick={handleProfileBack} className="btn default">
      <i><FaArrowLeft aria-hidden="true" /></i>
      </button>
  );
};

export default BackButton;
