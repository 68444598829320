import React, { useEffect, useState } from "react";
import { getAllBlog } from "../../services/apis";
import BlogCard from "../../components/blog/BlogCard";
import './BlogList.css'; // Import the CSS file for styles

const BlogList = () => {
    const [blogs, setBlogs] = useState([]);
    const [page, setPage] = useState(0);
    const [size] = useState(6); // Number of blogs per page
    const [totalPages, setTotalPages] = useState(1); // Total number of pages

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await getAllBlog(page, size);
                setBlogs(response.data.content); // Adjust based on your API response
                setTotalPages(response.data.page.totalPages); // Adjust based on your API response
            } catch (error) {
                console.error("Error fetching blogs:", error);
            }
        };

        fetchBlogs();
    }, [page, size]);

    // Handle next and previous page click
    const handleNextPage = () => {
        if (page < totalPages - 1) {
            setPage(page + 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    return (
        <div id="blogs" className="blog-list" style={{ marginTop: "35px" }}>
            <div className="container mt-3 pt-3">
                <div className="section-header">
                    <h2>{"Blogs"}</h2>
                </div>
                <div className="cards-container">
                    {blogs.map((blog) => (
                        <div className="blog-card" key={blog.id}>
                            <BlogCard title={blog.title} content={blog.content} id={blog.id} image={blog.image} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="pagination-controls">
                <button onClick={handlePrevPage} disabled={page === 0}>
                    {"<"}
                </button>
                <span>Page {page + 1}</span>
                <button onClick={handleNextPage} disabled={page === totalPages - 1}>
                    {">"}
                </button>
            </div>
        </div>
    );
};

export default BlogList;
