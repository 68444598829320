import React from "react";
import './AboutPage.css';
import { IMAGES } from "../../constants/images";
import SEO from '../../seo/SEO';

const AboutPage = () => {
  return (
    <section className="about-us">
      <SEO
        title="À Propos de Colocky | Colocation au Maroc"
        description="Colocky.ma est la meilleure plateforme pour trouver et proposer des colocations au Maroc. Nous aidons les étudiants et jeunes professionnels à partager des logements facilement."
        url={window.location.href}
      />
      <div className="about-about">
        <img src={IMAGES.aboutImage} className="about-pic" alt="About Us" />
        <div className="about-text">
          <h2>À Propos de Nous</h2>
          <h5>
            Bienvenue sur <span>colocky.ma</span>
          </h5>
          <p>
            Chez <strong>colocky.ma</strong>, nous sommes dédiés à simplifier la colocation au Maroc. Notre plateforme 
            vise à connecter les gens en quête de logements partagés, qu'il s'agisse d'étudiants, de jeunes professionnels 
            ou de toute autre personne cherchant à partager un espace de vie. Nous croyons en la colocation comme une 
            expérience enrichissante qui favorise l'échange et le partage.

            <p>
            Merci de choisir <strong>colocky.ma</strong>. Nous sommes là pour rendre votre expérience de colocation agréable et réussie.
          </p>
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutPage;
