import React from 'react';
import './PoliciesPage.css';

const Conditions = () => {
    return (
        <div className="terms-container">
            <div className="terms-content">
                <h1 className="terms-title">Conditions d'Utilisation</h1>
                <section className="terms-section">
                    <h2 className="terms-subtitle">1. Introduction</h2>
                    <p>Bienvenue sur colocky.ma. En accédant ou en utilisant ce site web colocky.ma, vous acceptez ces conditions d'utilisation (les "Conditions"). Si vous n'acceptez pas ces Conditions, veuillez ne pas utiliser le Site.</p>
                </section>
                <section className="terms-section">
                    <h2 className="terms-subtitle">2. Modifications des Conditions</h2>
                    <p>Nous nous réservons le droit de modifier ces Conditions à tout moment. Les modifications seront publiées sur cette page. Votre utilisation continue du Site après ces modifications signifie que vous acceptez les nouvelles Conditions.</p>
                </section>
                <section className="terms-section">
                    <h2 className="terms-subtitle">3. Accès au Site</h2>
                    <p>Nous pouvons suspendre ou retirer l'accès au Site à tout moment, sans préavis, pour des raisons telles que la maintenance ou des problèmes techniques.</p>
                </section>
                <section className="terms-section">
                    <h2 className="terms-subtitle">4. Utilisation du Site</h2>
                    <p>Vous devez utiliser le Site uniquement à des fins légales. Il est interdit de :</p>
                    <ul>
                        <li>Perturber ou endommager le Site.</li>
                        <li>Recueillir des données personnelles sans autorisation.</li>
                        <li>Utiliser des logiciels malveillants ou des techniques de piratage.</li>
                    </ul>
                </section>
                <section className="terms-section">
                    <h2 className="terms-subtitle">5. Propriété Intellectuelle</h2>
                    <p>Le contenu du Site est protégé par des droits d'auteur et des marques. Vous ne pouvez pas utiliser ce contenu sans notre autorisation.</p>
                </section>
                <section className="terms-section">
                    <h2 className="terms-subtitle">6. Liens vers des Sites Externes</h2>
                    <p>Le Site peut contenir des liens vers des sites tiers que nous ne contrôlons pas. Nous ne sommes pas responsables du contenu de ces sites.</p>
                </section>
                <section className="terms-section">
                    <h2 className="terms-subtitle">7. Responsabilité</h2>
                    <p>Une licence de sécurité est contractée, cependant nous ne garantissons pas que le site sera exempt d'erreurs ou de virus. Nous ne serons pas responsables des dommages indirects ou spéciaux liés à son utilisation.</p>
                </section>
                <section className="terms-section">
                    <h2 className="terms-subtitle">8. Indemnisation</h2>
                    <p>Vous acceptez de nous indemniser contre toute réclamation liée à votre utilisation du Site ou à une violation de ces Conditions.</p>
                </section>
                <section className="terms-section">
                    <h2 className="terms-subtitle">9. Loi Applicable</h2>
                    <p>Ces Conditions sont régies par les lois de Maroc. Tout litige sera soumis aux tribunaux de ce pays.</p>
                </section>
                <section className="terms-section">
                    <h2 className="terms-subtitle">10. Contact</h2>
                    <p>Pour toute question sur ces Conditions, contactez-nous à contact@colocky.ma.</p>
                </section>
            </div>
        </div>
    );
};

export default Conditions;
