import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../AuthPages.css";
import { resetPassword } from "../../../services/apis";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [authStatus, setAuthStatus] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [authMessage, setAuthMessage] = useState("");
  const navigate = useNavigate();
  const { token } = useParams();

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setAuthStatus(null);
    setAuthError(null);

    if (newPassword !== confirmPassword) {
      setAuthStatus("failed");
      setAuthError("Passwords do not match.");
      setIsSubmitting(false);
      return;
    }

    try {
      await resetPassword(token, newPassword);
      setAuthStatus("succeeded");
      setAuthMessage(
        "Password reset successfully. You can now log in with your new password."
      );
      navigate("/auth/login");
    } catch (error) {
      setAuthStatus("failed");
      setAuthError("Failed to reset password. Please try again.");
      console.error("Reset password error", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="auth-card">
      {/* <div className="logo">
            <img src={IMAGES.logo} alt="Colocky" />
          </div> */}
      <h2>Réinitialiser le mot de passe 🔒</h2>
      <p>
        Entrez un nouveau mot de passe pour réinitialiser votre mot de passe
      </p>

      {authStatus === "failed" && (
        <div className="alert error">{authError}</div>
      )}
      {authStatus === "succeeded" && (
        <div className="alert success">{authMessage}</div>
      )}

      <form onSubmit={onSubmit}>
        <div className="input-group">
          <label htmlFor="newPassword">Nouveau mot de passe</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Entrez votre nouveau mot de passe"
            className={authStatus === "failed" ? "error" : ""}
            required
          />
        </div>

        <div className="input-group">
          <label htmlFor="confirmPassword">Confirmer le mot de passe</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirmez votre nouveau mot de passe"
            className={authStatus === "failed" ? "error" : ""}
            required
          />
        </div>

        <button
          type="submit"
          className="auth-submit-btn"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Envoi..." : "Réinitialiser"}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
