import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';

function StepThreeConfirmationForm({ formData, previousStep, handleSubmit, setError }) {
  const validateAndSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    
    // Validation
    const { titre, villeName, quartier, budget, dateDisponibilite, specificDate, duree, description } = formData;
    
    // Clear previous errors
    setError('');
    
    // Debugging logs
    console.log('Form Data:', formData);
    
    // Perform validation and check if any field is missing
    if (!titre) {
      setError('Le titre est requis.');
    } else if (!villeName) {
      setError('La ville est requise.');
    } else if (!quartier) {
      setError('Le quartier est requis.');
    } else if (!budget) {
      setError('Le budget est requis.');
    } else if (!description) {
      setError('La description est requise.');
    } else if (!duree) {
      setError('La durée est requise.');
    } else if (dateDisponibilite === 'SPECIFIC' && !specificDate) {
      setError('Une date de disponibilité spécifique est requise.');
    } else {
      // Debugging log to confirm no validation errors
      console.log('Validation passed, submitting form...');
      handleSubmit(e); // Proceed with form submission
    }
  };

  return (
    <Container className="formContainer">
      <h2 className="formTitle">Confirmer les informations</h2>
      <Row>
        <Col md={6}>
          <Form>
            <Form.Group className="mb-3 formGroup">
              <Form.Label>Titre</Form.Label>
              <Form.Control value={formData.titre} readOnly />
            </Form.Group>
            <Form.Group className="mb-3 formGroup">
              <Form.Label>Ville</Form.Label>
              <Form.Control value={formData.villeName} readOnly />
            </Form.Group>
            <Form.Group className="mb-3 formGroup">
              <Form.Label>Quartier</Form.Label>
              <Form.Control value={formData.quartier} readOnly />
            </Form.Group>
            <Form.Group className="mb-3 formGroup">
              <Form.Label>Budget</Form.Label>
              <Form.Control value={formData.budget} readOnly />
            </Form.Group>
            <Form.Group className="mb-3 formGroup">
              <Form.Label>Disponibilité</Form.Label>
              <Form.Control 
                value={formData.dateDisponibilite === "IMMEDIATE" 
                  ? "Immédiatement disponible" 
                  : `Disponible à partir du ${formData.specificDate}`} 
                readOnly 
              />
            </Form.Group>
            <Form.Group className="mb-3 formGroup">
              <Form.Label>Durée (mois)</Form.Label>
              <Form.Control value={formData.duree} readOnly />
            </Form.Group>
            <Form.Group className="mb-3 formGroup">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" value={formData.description} readOnly />
            </Form.Group>
            <div className="formButtons">
              <button className="previous" type="button" onClick={previousStep}>Précédent</button>
              <button className="submit default" type="button" onClick={validateAndSubmit}>Soumettre</button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default StepThreeConfirmationForm;
