import React from 'react';
import MultiStepDemandeForm from './form/MultiStepDemandeForm';

function AddColocDemande() {
  return (
    <div className='coloc-form'
      style={{
        minHeight: '100vh',
        padding: "0px"
      }}
    >
      <MultiStepDemandeForm />
    </div>
  );
}

export default AddColocDemande;
