import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { staticVilles } from './constants';
import SelectionModal from '../modal/SelectionModal';
import SEO from '../../seo/SEO';

const ListVilles = () => {
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const [selectedVille, setSelectedVille] = useState(null);
  const scrollContainerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const autoScrollIntervalRef = useRef(null);

  const villesFromState = useSelector((state) => state.generalData.villeColocInfo) || staticVilles;

  // Filter and sort villes
  const villes = villesFromState
    .map(ville => ({
      ...ville,
      totalCount: ville.verifiedColocCount + ville.verifiedDemandeCount
    }))
    .filter(ville => ville.totalCount > 0)
    .sort((a, b) => b.totalCount - a.totalCount);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const stopAutoScroll = () => {
    if (autoScrollIntervalRef.current) {
      clearInterval(autoScrollIntervalRef.current);
      autoScrollIntervalRef.current = null;
    }
  };

  const scrollToNextItem = useCallback(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const itemWidth = container.children[0].offsetWidth/2;
    const scrollLeft = container.scrollLeft;
    const containerWidth = container.offsetWidth;

    if (scrollLeft + containerWidth >= container.scrollWidth - itemWidth / 2) {
      container.scrollTo({ left: 0, behavior: 'smooth' });
    } else {
      container.scrollTo({ left: scrollLeft + itemWidth, behavior: 'smooth' });
    }
  }, []);

  const startAutoScroll = useCallback(() => {
    if (autoScrollIntervalRef.current) return;

    autoScrollIntervalRef.current = setInterval(() => {
      scrollToNextItem();
    }, 3000); 
  }, [scrollToNextItem]);

  useEffect(() => {
    if (isMobile) {
      startAutoScroll();
    } else {
      stopAutoScroll();
    }

    return () => stopAutoScroll();
  }, [isMobile, startAutoScroll]);

  const handleCityClick = (ville) => {
    setSelectedVille(ville);
    setShowSelectionModal(true);
    stopAutoScroll();
  };

  const handleCloseSelectionModal = () => {
    setShowSelectionModal(false);
    setSelectedVille(null);
    if (isMobile) startAutoScroll();
  };

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const itemWidth = container.children[0].offsetWidth;
    const scrollAmount = direction === 'left' ? -itemWidth : itemWidth;

    if (direction === 'left' && container.scrollLeft === 0) {
      container.scrollTo({ left: container.scrollWidth - container.offsetWidth, behavior: 'smooth' });
    } else if (direction === 'right' && container.scrollLeft + container.offsetWidth >= container.scrollWidth - itemWidth / 2) {
      container.scrollTo({ left: 0, behavior: 'smooth' });
    } else {
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const showScrollButtons = !isMobile && villes.length > 3;

  return (
    <section
      style={{ fontFamily: 'Poppins, sans-serif' }}
      id="list-topics" 
      className="list-topics"
      onMouseEnter={stopAutoScroll}
      onMouseLeave={() => isMobile && startAutoScroll()}
    >
      {selectedVille && (
        <SEO 
          title={`Découvrez la colocation à ${selectedVille.nom}`} 
          description={`Explorez les offres et demandes de colocation à ${selectedVille.nom}. Découvrez les options de coliving et trouvez des colocataires à ${selectedVille.nom}.`}
          image={selectedVille.image} 
          url={`/coloc/ville/${selectedVille.id}`}
        />
      )}
      <div className="container">
        <div className={`carousel ${isMobile ? 'mobile-carousel' : ''}`}>
          <div 
            className="list-topics-content" 
            ref={scrollContainerRef}
            style={{
              overflowX: isMobile ? 'auto' : 'hidden',
              WebkitOverflowScrolling: 'touch',
              scrollSnapType: isMobile ? 'x mandatory' : 'none'
            }}
          >
            <ul className={!isMobile && villes.length <= 3 ? 'flex-row-center' : ''}>
              {villes.map((ville) => (
                <li 
                  key={ville.id} 
                  onClick={() => handleCityClick(ville)}
                  style={{ scrollSnapAlign: isMobile ? 'start' : 'none' }}
                >
                  <div
                    className="single-list-topics-content"
                    style={{ '--bg-image': `url(${ville.image})`, fontFamily: 'Poppins, sans-serif' }}
                  >
                    <div className="single-list-topics-icon">
                      <i className="flaticon-placeholder"></i>
                    </div>
                    <h2 style={{ fontFamily: 'Poppins, sans-serif' }}>{ville.nom}</h2>
                    <p style={{ fontFamily: 'Poppins, sans-serif' }}>{ville.totalCount}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {showScrollButtons && (
            <>
              <div className="forward" onClick={() => scroll('right')}>
                <div className="arrow"></div>
              </div>
              <div className="backward" onClick={() => scroll('left')}>
                <div className="arrow"></div>
              </div>
            </>
          )}
        </div>
      </div>
      {showSelectionModal && (
        <SelectionModal 
          ville={selectedVille} 
          onClose={handleCloseSelectionModal}
        />
      )}

      {selectedVille && (
        <SelectionModal
          isOpen={showSelectionModal}
          onClose={handleCloseSelectionModal}
          title={`Découvrez les opportunités à ${selectedVille.nom}`}
          description={`Explorez les différentes options disponibles à ${selectedVille.nom}. Souhaitez-vous consulter les offres de colocation ou voir les demandes de colocataires?`}
          villeId={selectedVille.id}
          links={[
            {
              path: `/coloc/ville/${selectedVille.id}/offres`,
              label: "Offres",
              title: "Offres",
              icon: "FaSearch",
              description: `Vous avez trouvé un logement à ${selectedVille.nom}? Consultez les offres pour trouver des colocataires prêts à partager cet espace.`
            },
            {
              path: `/coloc/ville/${selectedVille.id}/demandes`,
              label: "Demandes",
              title: "Demandes",
              icon: "FaHome",
              description: `Vous cherchez à rejoindre une colocation à ${selectedVille.nom}? Parcourez les demandes pour trouver un logement qui vous correspond.`
            }
          ]}
        />
      )}
    </section>
  );
};

export default ListVilles;