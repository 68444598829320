import { useEffect, useState } from "react";
import BlogCard from "./BlogCard";
import { getAllBlog } from "../../services/apis";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";


const BlogSection = () => {

    const [blogs, setBlogs] = useState([]);
    const [page] = useState(0);
    const [size] = useState(3);
    const navigate = useNavigate();

    // Fetch blogs when component mounts or page changes
    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await getAllBlog(page, size);
                setBlogs(response.data.content); // Adjust based on your API response
            } catch (error) {
                console.error("Error fetching blogs:", error);
            }
        };

        fetchBlogs();
    }, [page, size]);


    return (
        <div  id="blogs" className="container mt-3 pt-3">
            <div className="section-header mt-5">
                <h2>{"Blogs"}</h2>
            </div>
            <div className="row">
                {
                    blogs.map((blog, id) => (<div className="col-md-4">
                        <BlogCard title={blog.title} content={blog.content} id={blog.id} image={blog.image} />
                    </div>))
                }
            </div>
            <div
                className="flex-center"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <button
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                        color: "#ff545a",
                        textDecoration: "none",
                        padding: "0.5rem 1rem",
                        border: "1px solid #ff545a",
                        marginTop: "2rem",
                      }}
                      onClick={() => navigate("/blogs")}
                >
                    Voir plus <FaArrowRight />
                </button>
            </div>

        </div>
    );
};

export default BlogSection;