import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getOffre, updateOffre } from "../../services/apis";
import { ELogementType } from "../../utils/enums";

const EditOffre = () => {
    const { id } = useParams();
    const [offre, setOffre] = useState({
        id: null,
        titre: "",
        quartier: "",
        description: "",
        prix: "",
        superficie: 0,
        nbrColoc: 0,
        logementType: "",
        villeId: null,
        dateDisponibilite: "",
        duree: null,
        proprietaireId: null,
        isVerified: false,
        statut: "",
    });
    const [message, setMessage] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await getOffre(id);
                setOffre(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        let updatedValue = value;

        switch (name) {
            case 'superficie':
            case 'nbrColoc':
                updatedValue = value === '' ? 0 : parseFloat(value);
                break;
            case 'villeId':
            case 'proprietaireId':
                updatedValue = value === '' ? null : parseInt(value, 10);
                break;
            case 'duree':
                updatedValue = value === '' ? null : parseInt(value, 10);
                break;
            case 'isVerified':
                updatedValue = type === 'checkbox' ? e.target.checked : value;
                break;
            case 'dateDisponibilite':
                updatedValue = value ? value : null;
                break;
            default:
                break;
        }

        setOffre(prevOffre => ({
            ...prevOffre,
            [name]: updatedValue,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const updatedOffre = {
                ...offre,
                superficie: parseFloat(offre.superficie) || 0,
                nbrColoc: parseFloat(offre.nbrColoc) || 0,
                duree: offre.duree !== null ? parseInt(offre.duree, 10) : null,
                dateDisponibilite: offre.dateDisponibilite ? new Date(offre.dateDisponibilite).toISOString().split('T')[0] : null,
                photos: [],
            };
            await updateOffre(id, updatedOffre);
            setMessage("Offre mise à jour avec succès !");
        } catch (error) {
            if (!error.response) {
                setMessage("Erreur de réseau. Veuillez vérifier votre connexion Internet.");
            } else if (error.response.status === 400) {
                setMessage("Erreur de validation. Veuillez vérifier les informations saisies.");
            } else {
                setMessage("Une erreur s'est produite lors de la mise à jour de l'offre.");
            }
        }
    };
        
    return (
        <div className="container col-md-8" style={{margin:"20px" , marginBottom:"40px"}}>
            <div>
                <div>
                    <div className="row">
                        <div className="col-md-4 offset-md-3">
                            <h2 className="formTitle text-center">Modifier l'Offre</h2>
                        </div>
                        <div className="col-md-8 offset-md-3 formContainer">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="titre">Titre</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="titre"
                                        name="titre"
                                        value={offre.titre}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="quartier">Quartier</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="quartier"
                                        name="quartier"
                                        value={offre.quartier}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="description">Description</label>
                                    <textarea
                                        className="form-control"
                                        id="description"
                                        name="description"
                                        value={offre.description}
                                        onChange={handleChange}
                                        rows={3}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="prix">Prix</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="prix"
                                        name="prix"
                                        value={offre.prix}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="superficie">Superficie</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="superficie"
                                        name="superficie"
                                        value={offre.superficie}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="nbrColoc">Nombre de colocataires</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="nbrColoc"
                                        name="nbrColoc"
                                        value={offre.nbrColoc}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="logementType">Type de logement</label>
                                    <select
                                        className="form-control"
                                        id="logementType"
                                        name="logementType"
                                        value={offre.logementType}
                                        onChange={handleChange}
                                        required
                                    >
                                        {Object.values(ELogementType).map((type) => (
                                            <option key={type} value={type}>
                                                {type}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="dateDisponibilite">Date de disponibilité</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="dateDisponibilite"
                                        name="dateDisponibilite"
                                        value={offre.dateDisponibilite}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="duree">Durée (en mois)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="duree"
                                        name="duree"
                                        value={offre.duree}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="statut">Statut</label>
                                    <div className="form-control" style={{ backgroundColor: "#f8f9fa", border: "none" }}>
                                        {offre.statut}
                                    </div>
                                </div>

                                <button type="submit" className="btn btn-primary">
                                    Enregistrer
                                </button>
                                <span style={{margin:"5px"}}>
                                    {message}
                                </span>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditOffre;
