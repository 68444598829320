import React from "react";

export const truncate = (str, num) => {
    if (str.length <= num) return str;
    return str.slice(0, num) + "...";
};

export const parseSimpleHtml = (html) => {
    const parsed = html.split(/<br\s*\/?>/i).map((text, index) => (
        <React.Fragment key={index}>
            {text.split(/(<b>.*?<\/b>)/).map((part, i) => {
                if (part.startsWith('<b>') && part.endsWith('</b>')) {
                    return <strong key={i}>{part.slice(3, -4)}</strong>;
                }
                return part;
            })}
            {index !== html.split(/<br\s*\/?>/i).length - 1 && <br />}
        </React.Fragment>
    ));
    return parsed;
};
// export const parseHtml = (html) => {
//     const parseNode = (node) => {
//       if (node.nodeType === Node.TEXT_NODE) {
//         return node.textContent;
//       }
//       if (node.nodeType === Node.ELEMENT_NODE) {
//         const children = Array.from(node.childNodes).map(parseNode);
//         switch (node.tagName.toLowerCase()) {
//           case 'br':
//             return <br key={Math.random()} />;
//           case 'b':
//           case 'strong':
//             return <strong key={Math.random()}>{children}</strong>;
//           case 'i':
//           case 'em':
//             return <em key={Math.random()}>{children}</em>;
//           case 'u':
//             return <u key={Math.random()}>{children}</u>;
//           case 'p':
//             return <p key={Math.random()}>{children}</p>;
//           case 'a':
//             return <a key={Math.random()} href={node.getAttribute('href')}>{children}</a>;
//           default:
//             return <span key={Math.random()}>{children}</span>;
//         }
//       }
//       return null;
//     };
  
//     const parser = new DOMParser();
//     const doc = parser.parseFromString(html, 'text/html');
//     return Array.from(doc.body.childNodes).map(parseNode);
//   };


const voidElements = new Set([
    'area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 
    'link', 'meta', 'param', 'source', 'track', 'wbr'
  ]);
  
  export const parseHtml = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    
    const parseNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent;
      }
      if (node.nodeType === Node.ELEMENT_NODE) {
        const tagName = node.tagName.toLowerCase();
        const attributes = Array.from(node.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        
        if (voidElements.has(tagName)) {
          return { type: tagName, props: attributes };
        }
        
        const children = Array.from(node.childNodes).map(parseNode).filter(Boolean);
        return {
          type: tagName,
          props: { ...attributes, children: children.length === 1 ? children[0] : children }
        };
      }
      return null;
    };
  
    return Array.from(tempDiv.childNodes).map(parseNode).filter(Boolean);
  };