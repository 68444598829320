import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllOffres} from '../../services/apis';

export const fetchColocations = createAsyncThunk('coloc/fetchColocations', async () => {
    const response = await getAllOffres.get(`/test/coloc`);
    return response.data;
  });
  
  const colocSlice = createSlice({
    name: 'coloc',
    initialState: {
      data: [],
      status: 'idle',
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchColocations.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchColocations.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.data = action.payload;
        })
        .addCase(fetchColocations.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });
  
  export default colocSlice.reducer;
