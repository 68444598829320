export const getStorageTimestamp = () => {
    return localStorage.getItem('storageTimestamp');
};

export const setStorageTimestamp = () => {
    localStorage.setItem('storageTimestamp', new Date().toISOString());
};

export const clearStorageIfExpired = () => {
    const lastTimestamp = getStorageTimestamp();
    const now = new Date();
    
    if (lastTimestamp) {
        const lastDate = new Date(lastTimestamp);
        const timeDifference = now - lastDate;
        const hoursDifference = timeDifference / (1000 * 60 * 60);
        
        if (hoursDifference >= 24) {
            localStorage.clear();
            setStorageTimestamp();
        }
    } else {
        setStorageTimestamp();
    }
};
