import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import "../../assets/css/profile.css";
import Modal from "../../components/profile/Modal";
import { useError } from "../../components/providers/ErrorProvider";
import { getOffresByUserId, getUtilisateur } from "../../services/apis";
import DemandDetailsCard from "./profile-demand-details/DemandDetailsCard";
import ProfileNav from "./profile-navigation/ProfileNav";
import ProfileInfoCard from "./profile-public-info/UserProfilePublicInfo";

const Profile = () => {
  const { user } = useSelector((state) => state.auth);
  const profileFilled = useSelector((state) => state.auth.profileFilled);
  const [showModal, setShowModal] = useState(false);
  const [profileData, setProfileData] = useState({
    offres: [],
    userInfo: null,
  });
  const [demandeData, setDemandeData] = useState(null);
  const navigate = useNavigate();
  const { showError } = useError();

  const checkUserInfo = useCallback(() => {
    const { userInfo } = profileData;
    if (!userInfo) return;
    const missingInfo =
      ["telephone", "nom", "prenom"].some((field) => !userInfo[field]) ||
      userInfo.age === 0;
    setShowModal(missingInfo);
  }, [profileData]);

  const fetchProfileData = useCallback(async () => {
    try {
      if (!user) return;
      const [offresData, userData] = await Promise.all([
        getOffresByUserId(0, 10, user.id),
        getUtilisateur(user.id),
      ]);

      const updatedProfileData = {
        offres: offresData.data.content || [],
        userInfo: userData.data,
      };

      console.log(updatedProfileData);

      setProfileData(updatedProfileData);
      setDemandeData(userData.data.demande);
    } catch (error) {
      showError(
        "Erreur lors de la récupération des données de profil : " +
        error.message
      );
    }
  }, [user, showError]);

  useEffect(() => {
    if (user) {
      if (!profileFilled) {
        navigate("/edit-profile");
      } else {
        fetchProfileData();
      }
    } else {
      navigate("/auth");
    }
  }, [user, profileFilled, fetchProfileData, navigate]);

  useEffect(() => {
    if (profileData.userInfo) {
      checkUserInfo();
    }
  }, [profileData.userInfo, checkUserInfo]);

  const handleNavigation = (path) => navigate(path);

  return (
    <section>
      <div className="container bootstrap snippet">
        <div className="row">
          <div className="col-md-4">
            <ProfileNav 
              identityVerified={profileData?.userInfo?.identityVerified}
              handleNavigation={handleNavigation} 
              profileData={profileData} 
            />
          </div>
          <div className="col-md-8">
            <ProfileInfoCard profileData={profileData} />
            <DemandDetailsCard demandeData={demandeData} />
          </div>
        </div>
      </div>
      <Outlet />
      {showModal && <Modal showModal={showModal} />}
    </section>
  );
};

export default Profile;