import { useNavigate, useParams } from "react-router-dom";
import { verifyEmail } from "../../../services/apis";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const EmailVerification = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const isAuthenticated = useSelector((state) => !!state.auth.user);


  useEffect(()=>{
    const triggerVerify = async (e) => {
      e.preventDefault();
  
      try {
        const response = await verifyEmail(token);
        alert(response.data);
        navigate("/");
      } catch (error) {
        alert(
          error.response
            ? error.response.data
            : "An error occurred during verification."
        );
      }
    };
    if(isAuthenticated)
      triggerVerify()
  },[isAuthenticated, navigate, token])

  const handleVerify = async (e) => {
    e.preventDefault();

    try {
      const response = await verifyEmail(token);
      alert(response.data);
      navigate("/");
    } catch (error) {
      alert(
        error.response
          ? error.response.data
          : "An error occurred during verification."
      );
    }
  };

  return (
    <div className="auth-card">
      {/* <div className="logo">
        <img src={IMAGES.logo} alt="Colocky" />
      </div> */}
      <h2>{"Vérifier votre email"}</h2>
      <button type="submit" className="auth-submit-btn" onClick={handleVerify}>
        {"Vérifier"}
      </button>
    </div>
  );
};

export default EmailVerification;
