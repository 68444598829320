import React from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import '../../../../assets/css/custom-form.css';

function StepOneUserForm({ formData,user, handleChange, nextStep }) {
  return (
    <Container className="formContainer">
      <h2 className='formTitle'>Confirmez vos informations</h2>
      <Row>
        <Col md={{ span: 12, offset: 3 }}>
          <Form>
            <Form.Group className="mb-3 formGroup">
              <Form.Label>Nom</Form.Label>
              <Form.Control
                value={user.username}
                onChange={handleChange}
                type="text"
                name="nom"
                required
              />
            </Form.Group>
            <Form.Group className="formGroup">
              <Form.Label>Email</Form.Label>
              <Form.Control
                value={user.email}
                onChange={handleChange}
                type="email"
                name="email"
                required
              />
            </Form.Group>
            <Button
              onClick={nextStep}
              className='default' variant='' type="button">Suivant</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default StepOneUserForm;
