
const AbonnementModal = ({ abonnement, showModal, handleClose }) => {
    if (!showModal) return null;

    return (
        <div className="modal show" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{abonnement.type} - Détails</h5>
                    </div>
                    <div className="modal-body">
                        <p>{abonnement.description}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" onClick={handleClose}>
                            Fermer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AbonnementModal;