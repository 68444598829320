import React, { useState } from "react";
import { sendMessage } from "../services/apis";

const Subscription = () => {
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState(""); // State to store the response

  const handleSubscription = async (e) => {
    e.preventDefault();
    try {
      const response = await sendMessage({subject,message,email});
      console.log(response?.status);
      if (response?.status === 200) {
        setResponseMessage("Message envoyé avec succès!"); 
        setSubject("");
        setEmail("");
        setMessage("");
      } else {
        setResponseMessage("Erreur lors de l'envoi du message. Veuillez réessayer.");
      }
    } catch (error) {
      console.error("There was an error!", error);
      setResponseMessage("Erreur lors de l'envoi du message. Veuillez réessayer.");
    }
  };

  return (
    <section id="contact" className="subscription">
      <div className="container">
        <div className="subscribe-title text-center">
          <h2>Saisissez votre message</h2>
          <p>Nous vous garantissons une réaction immédiate.</p>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="subscription-input-group">
              <form onSubmit={handleSubscription}>
                <input
                  type="text"
                  className="subscription-input-form"
                  placeholder="Votre sujet"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                />
                <input
                  type="email"
                  className="subscription-input-form"
                  placeholder="Votre email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <textarea
                  className="subscription-input-form"
                  placeholder="Votre message"
                  rows="8"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
                <button type="submit" className="btn default">
                  Envoyer
                </button>
              </form>
              {responseMessage && (
                <p className="response-message">{responseMessage}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscription;
