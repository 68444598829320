import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import BackButton from "../../components/BackButton";
import { getAllVilles, getDemande, updateDemande } from "../../services/apis";
import { useNavigate, useParams } from "react-router-dom";

const schema = yup.object().shape({
  titre: yup.string().required("Le titre est requis"),
  description: yup.string().required("La description est requise"),
  budget: yup.string().required("Le budget est requis"),
  dateDisponibilite: yup.date().required("La date de disponibilité est requise"),
  villeId: yup.number().positive().required("La ville est requise"),
  quartier: yup.string().required("Le quartier est requis"),
});

const EditDemande = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      titre: "",
      description: "",
      budget: "",
      dateDisponibilite: "",
      villeId: "",
      quartier: "",
    },
  });
  const { demandeId } = useParams();
  const [villes, setVilles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVilles = async () => {
      try {
        let res = await getAllVilles();
        setVilles(res.data.content);
      } catch (error) {
        console.log(error);
      }
    };
   
    if (villes?.length === 0) fetchVilles();
  }, [villes?.length]);

  useEffect(() => {
    const fetchDemandeData = async () => {
      try {
        const response = await getDemande(demandeId);
        const demandeData = response.data;
        const formattedData = {
          ...demandeData,
          dateDisponibilite: new Date(demandeData.dateDisponibilite).toISOString().split('T')[0],
          villeId: demandeData?.ville?.id,
        };
        reset(formattedData);
      } catch (err) {
        console.error("Error fetching demande data", err);
        alert("Vous n'avez pas une demande");
        navigate("/profile");
      }
    };
    fetchDemandeData();
  }, [demandeId, reset, navigate]);

  const onSubmit = async (data) => {
    try {
      await updateDemande(demandeId, data);
      alert("Demande updated successfully!");
    } catch (error) {
      console.error("Error updating demande", error);
      alert("Failed to update demande");
    }
  };

  const renderFormField = (label, name, type = "text", readOnly = false) => (
    <Col md={{ span: 6, offset: 0 }}>
      <Form.Group className="mb-3 formGroup">
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type={type}
          {...register(name)}
          readOnly={readOnly}
          isInvalid={!!errors[name]}
        />
        <Form.Control.Feedback className="invalid" type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </Col>
  );

  const renderSelectField = (label, name, options) => (
    <Col md={{ span: 6, offset: 0 }}>
      <Form.Group className="mb-3 formGroup">
        <Form.Label>{label}</Form.Label>
        <Form.Control as="select" {...register(name)} isInvalid={!!errors[name]}>
          <option value="">Choisir...</option>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.nom}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback className="invalid" type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </Col>
  );

  return (
    <Container>
      <Row>
        <Col>
          <BackButton path="/profile" />
        </Col>
      </Row>
      <Row>
        <h1 className="formTitle">Mise à jour la demande</h1>
      </Row>
      <div className="formContainer">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="nice-form-group">
            {renderFormField("Titre", "titre")}
            {renderFormField("Description", "description")}
            {renderFormField("Budget", "budget")}
            {renderFormField("Date de Disponibilité", "dateDisponibilite", "date")}
            {renderSelectField("Ville", "villeId", villes)}
            {renderFormField("Quartier", "quartier")}
            <Button type="submit" variant="" className="default">
              Enregistrer
            </Button>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default EditDemande;