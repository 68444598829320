import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard = (props) => {
  
  const truncateString = (str, length) => {
    if (str?.length > length) {
        return str?.slice(0, length) + '...';
    }
    return str;
  }

  return (
    <div className="single-blog-item" style={{ margin: '20px' }}>
      <div className="single-blog-item-img">
        <img
          src={props.image || "https://via.placeholder.com/300"}
          alt={`image ${props.image}`}
          style={{ width: '100%', height: 'auto' }}
        />
      </div>
      <div className="single-blog-item-txt">
        <h2>
          <Link to={`/blogs/${props.id}`}>
            {truncateString(props.title, 50)}
          </Link>
        </h2>
        <p>
          {truncateString(props.content, 100)}
        </p>
      </div>
    </div>
  );
};

export default BlogCard;
