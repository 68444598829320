import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { getUtilisateur, updateUtilisateur } from "../../services/apis";
import Toast from "../../components/toast/Toast.jsx";
import { setProfileFilled } from "../../state/slices/authSlice.js";

// Validation schema
const schema = yup.object().shape({
  nom: yup.string().required("Le nom est requis"),
  prenom: yup.string().required("Le prénom est requis"),
  telephone: yup
    .string()
    .matches(
      /^[0-9\s\-+()]{10,15}$/,
      "Le téléphone doit être un numéro valide."
    )
    .required("Le téléphone est requis"),
  age: yup
    .number()
    .positive("L'âge doit être positif")
    .integer("L'âge doit être un entier")
    .required("L'âge est requis"),
  apropos: yup.string(),
  langues: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
    )
    .min(1, "Sélectionnez au moins une langue"),
  tags: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
    )
    .min(3, "Sélectionnez au moins trois tags d'intérêt"),
});

const ProfileInfosWithFormValidation = () => {
  const navigate = useNavigate();
  const { user, profileFilled } = useSelector((state) => state.auth);
  const { tags, languages, loading, error } = useSelector((state) => state.generalData);
  const [toast, setToast] = useState({ show: false, title: '', desc: '' });
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.id) {
        try {
          const response = await getUtilisateur(user.id);
          const userData = response.data;

          // Format user data for react-select
          const formattedLangues = userData.langues
            ? userData.langues.map((lang) => ({ value: lang, label: lang }))
            : [];
          const formattedTags = userData.tags
            ? userData.tags.map((tag) => ({ value: tag, label: tag }))
            : [];

          setValue("langues", formattedLangues);
          setValue("tags", formattedTags);

          // Set other values
          Object.keys(userData).forEach((key) => {
            if (key !== "langues" && key !== "tags") {
              setValue(key, userData[key]);
            }
          });

          // Show toast if profile is not filled
          if (!profileFilled) {
            setToast({
              show: true,
              title: "Complétez votre profil",
              desc: "Veuillez saisir vos informations pour bénéficier d'une meilleure expérience.",
            });
            setTimeout(() => setToast({ show: false, title: "", desc: "" }), 5000);
          }
        } catch (err) {
          console.error("Error fetching user data", err);
        }
      }
    };

    fetchUserData();
  }, [user, setValue, profileFilled]);

  const onSubmit = async (data) => {
    try {
      const formattedData = {
        ...data,
        langues: data.langues.map((lang) => lang.value),
        tags: data.tags.map((tag) => tag.value),
      };
      await updateUtilisateur(user.id, formattedData);

      // Update profileFilled state in Redux and localStorage
      dispatch(setProfileFilled(true));

      setToast({
        show: true,
        title: "Success",
        desc: "User information updated successfully!",
      });
      setTimeout(() => setToast({ show: false, title: "", desc: "" }), 1000);
      setTimeout(() => {
        navigate("/profile");
      }, 1500);
    } catch (error) {
      console.error("Error updating user information", error);
      setToast({
        show: true,
        title: "Error",
        desc: "Failed to update user information",
      });
      setTimeout(() => setToast({ show: false, title: "", desc: "" }), 3000);
    }
  };

  const renderFormField = (label, name, type = "text", readOnly = false) => (
    <Col md={{ span: 6, offset: 0 }} key={name}>
      <Form.Group className="mb-3 formGroup">
        <Form.Label>
          {label}
        </Form.Label>
        <Form.Control
          type={type}
          {...register(name)}
          readOnly={readOnly}
          isInvalid={!!errors[name]}
        />
        <Form.Control.Feedback className="invalid" type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </Col>
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  return (
    <Container>
      <Row>
        <Col>
          <BackButton path="/profile" />
        </Col>
      </Row>
      <Row>
        <h1 className="formTitle">Profile infos</h1>
      </Row>
      <div className="formContainer">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="nice-form-group">
            <div className="d-flex align-items-center mb-3">
              <div>
                <h5 className="card-title mb-0">
                  {user.nom} {user.prenom}
                </h5>
              </div>
            </div>
            {renderFormField("Username", "username", "text", true)}
            {renderFormField("Email", "email", "email", true)}
            {renderFormField("Nom", "nom")}
            {renderFormField("Prénom", "prenom")}
            {renderFormField("Telephone", "telephone")}
            {renderFormField("Age", "age", "number")}
            <Col md={{ span: 6, offset: 0 }}>
              <Form.Group className="mb-3 formGroup">
                <Form.Label>Langues</Form.Label>
                <Controller
                  name="langues"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={languages.map((lang) => ({ value: lang, label: lang }))}
                      isMulti
                      classNamePrefix="react-select"
                      placeholder="Sélectionnez les langues"
                    />
                  )}
                />
                {errors.langues && <p className="text-danger">{errors.langues.message}</p>}
              </Form.Group>
            </Col>
            <Col md={{ span: 6, offset: 0 }}>
              <Form.Group className="mb-3 formGroup">
                <Form.Label>Tags d'intérêt</Form.Label>
                <Controller
                  name="tags"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={tags.map((tag) => ({ value: tag, label: tag }))}
                      isMulti
                      classNamePrefix="react-select"
                      placeholder="Sélectionnez les tags d'intérêt"
                    />
                  )}
                />
                {errors.tags && <p className="text-danger">{errors.tags.message}</p>}
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3 formGroup">
                <Form.Label>À propos</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  {...register("apropos")}
                  isInvalid={!!errors.apropos}
                />
                <Form.Control.Feedback className="invalid" type="invalid">
                  {errors.apropos?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Button type="submit" variant="" className="default">
              Enregistrer
            </Button>
          </div>
        </Form>
      </div>
      {toast.show && (
        <Toast title={toast.title} desc={toast.desc} delay={5000} onClose={() => setToast({ show: false, title: "", desc: "" })} />
      )}
      <style jsx>{`
        .formContainer {
          background: white;
          border-radius: 8px;
          padding: 20px;
        }

        .formTitle {
          font-size: 1.5em;
          margin-bottom: 20px;
        }

        .nice-form-group {
          background-color: #f5f5f5;
          border-radius: 8px;
          padding: 20px;
          margin-bottom: 20px;
        }

        .formGroup .invalid {
          display: block;
          color: red;
          margin-top: 5px;
        }

        .react-select__control {
          border: 1px solid #ced4da;
        }

        .react-select__control.is-invalid {
          border-color: red;
        }

        .react-select__menu {
          z-index: 5;
        }

        .text-danger {
          margin-top: 0.25rem;
        }
      `}</style>
    </Container>
  );
};

export default ProfileInfosWithFormValidation;
