import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './state/store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ErrorProvider } from './components/providers/ErrorProvider';
import AlertError from './components/alert-error/AlertError';

const rootElement = document.getElementById('root');

const AppWithProviders = () => (
  <React.StrictMode>
    <ErrorProvider>
      <Provider store={store}>
        <App />
        <AlertError />
      </Provider>
    </ErrorProvider>
  </React.StrictMode>
);

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(rootElement, <AppWithProviders />);
} else {
  ReactDOM.createRoot(rootElement).render(<AppWithProviders />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();