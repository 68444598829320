import React, { useState, useEffect } from 'react';
import { uploadImage } from '../../../../services/apis';
import ImageUploadForm from './ImageUploadForm';
import { Col, Container, Row } from 'react-bootstrap';

function StepThreeImageUploadForm({ formData = {}, previousStep, nextStep, handleImageChange, handleImages, images: initialImages }) {
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  const MAX_PHOTOS = 6;
  const ALLOWED_FORMATS = ['image/jpeg', 'image/png', 'image/webp', 'image/gif']; // Allowed image formats

  const [images, setImages] = useState(initialImages); // All images, including those being uploaded
  const [readyImages, setReadyImages] = useState(initialImages.filter(img => img.status === 'ready')); // Only ready images
  const [error, setError] = useState(''); // To handle validation errors

  useEffect(() => {
    handleImages(readyImages);
  }, [readyImages, handleImages]);

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);

    // Check if number of images exceeds the limit
    if (images.length + files.length > MAX_PHOTOS) {
      setError(`Vous ne pouvez pas télécharger plus de ${MAX_PHOTOS} photos.`);
      return;
    }

    // Validate file size and format, filter out invalid files
    const newImages = files.filter(file => {
      if (file.size > MAX_FILE_SIZE) {
        setError(`La taille de chaque fichier ne doit pas dépasser 5MB.`);
        return false;
      }
      if (!ALLOWED_FORMATS.includes(file.type)) {
        setError(`Le format du fichier n'est pas pris en charge. Veuillez utiliser un format compatible (JPEG, PNG, WebP, GIF).`);
        return false;
      }
      return true;
    }).map(file => ({
      file,
      preview: URL.createObjectURL(file),
      status: 'uploading'
    }));

    setImages(prevImages => [...prevImages, ...newImages]);

    // Upload images
    const uploadPromises = newImages.map(async (image) => {
      try {
        const formData = new FormData();
        formData.append('file', image.file);

        const response = await uploadImage(formData);

        if (response.data.success) {
          return {
            ...image,
            status: 'ready',
            src: response.data.fileName
          };
        } else {
          console.error(`Échec du téléchargement de l'image : ${response.data.message}`);
          return { ...image, status: 'error' };
        }
      } catch (error) {
        console.error('Erreur lors du téléchargement de l\'image :', error);
        return { ...image, status: 'error' };
      }
    });

    const uploadedImages = await Promise.all(uploadPromises);

    // Update the images with the uploaded status
    setImages(prevImages => {
      const updatedImages = prevImages.map(img =>
        uploadedImages.find(uploadedImg => uploadedImg.preview === img.preview) || img
      );
      return updatedImages;
    });

    const newReadyImages = uploadedImages.filter(img => img.status === 'ready');
    setReadyImages(prevReadyImages => [...prevReadyImages, ...newReadyImages]);

    const newPhotoStrings = newReadyImages.map(img => img.src);
    handleImageChange([...formData.photos, ...newPhotoStrings]);
  };

  const handleImageDelete = (imageToDelete) => {
    setImages(prevImages => prevImages.filter(image => image !== imageToDelete));

    if (imageToDelete.status === 'ready') {
      setReadyImages(prevReadyImages => prevReadyImages.filter(image => image !== imageToDelete));
      handleImageChange(formData.photos.filter(obj => obj.src !== imageToDelete.src));
    }
  };

  return (
    <Container className='formContainer'>
      <h2 className='formTitle'>Images du Logement</h2>
      <Row>
        <Col md={{ span: 12, offset: 3 }}>
          <ImageUploadForm images={images} onFileChange={handleFileChange} onDelete={handleImageDelete} error={error} />
          <div className="formGroup">
            <button className="default" type="button" onClick={previousStep}>Précédent</button>
            <button
              className="default"
              type="button"
              onClick={nextStep}
              disabled={images.some(image => image.status === 'uploading') || readyImages.length === 0}
            >
              Suivant
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default StepThreeImageUploadForm;
 