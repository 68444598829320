import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../constants/images";

const LogoImage = () => {
  return (
    <Link className="navbarbrand" to="/">
      <img
        src={IMAGES.logo}
        style={{
          width: "80px",
          padding: "0 10px",
          marginTop: "0px",
        }}
        alt="logo"
      />
    </Link>
  );
};

export default LogoImage;
