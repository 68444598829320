import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaLinkedin, FaGooglePlus, FaEnvelope, FaAngleUp } from 'react-icons/fa'; // Importing required icons
import LogoImage from './logo-image/LogoImage';

const Footer = () => {
  return (
    <footer id="footer" className="footer"
      style={{
        fontFamily: 'Poppins, sans-serif',
      }}
    >
      <div className="container">
        <div className="footer-menu">
          <div className="row">
            <div className="col-sm-3">
              <div className="navbar-header">
                <LogoImage />
              </div>
            </div>
            <div className="col-sm-9">
              <ul className="footer-menu-item">
                <li className="scroll"><Link to="/">{"acceuil"}</Link></li>
                <li className="scroll"><Link to="/about">{"à propos"}</Link></li>
                <li className="scroll"><Link to="/policies">{"Politique de Confidentialité"}</Link></li>
                <li className="scroll"><Link to="/conditions">{"Conditions générales d'utilisation"}</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="hm-footer-copyright">
          <div className="row">
            <div className="col-sm-5">
              <p>
                {"conçu et développé par "} <a href='https://competences-services.ma/'>competences-services</a> &copy; copyright.
              </p>
            </div>
            <div className="col-sm-7">
              <div className="footer-social">
                <span>
                  <FaEnvelope /> contact@colocky.ma
                </span>
                <a href="https://web.facebook.com/colocky.ma"><FaFacebook /></a>
                <a href="https://www.linkedin.com/company/colockyma"><FaLinkedin /></a>
                <a href="https://colocky.ma"><FaGooglePlus /></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="scroll-Top">
        <div className="return-to-top">
          <FaAngleUp id="scroll-top" data-toggle="tooltip" data-placement="top" title="Back to Top" aria-hidden="true" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
