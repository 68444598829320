import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useError } from "../../providers/ErrorProvider";
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";
import ColocItemDemand from "./coloc-item-demand/ColocItemDemand";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const DemandeList = ({ getLogementsApi, title }) => {
  const { villeId } = useParams();
  const [logements, setLogements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(12);
  const { showError } = useError();

  useEffect(() => {
    const fetchLogements = async () => {
      try {
        setLoading(true);
        const response = await getLogementsApi(currentPage, pageSize, villeId);
        setLogements(response.data.content);
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des logements:", error);
        setLoading(false);
      }
    };

    fetchLogements();
  }, [getLogementsApi, villeId, currentPage, pageSize, showError]);

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (logements.length === pageSize) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <section id="explore" className="explore">
      <div className="container">
        <div className="section-header">
          <h2>{title}</h2>
          <p>Explorer les colocations récemment publiées</p>
        </div>

        <div className="explore-content">
          <div className="row explore-grid">
            {logements.length > 0 ? (
              logements.map((logement) => (
                <div className="col-md-4 col-sm-6">
                  <ColocItemDemand
                    key={logement.id}
                    id={logement.id}
                    titre={logement.titre}
                    adresse={logement.adresse}
                    description={logement.description}
                    budget={logement.budget}
                    dateDisponibilite={logement.dateDisponibilite}
                    duree={logement.duree}
                    ville={logement.ville}
                    demandeur={logement.demandeur}
                  />
                </div>
              ))
            ) : (
              <div className="flex-row-center">
                <p>Aucun logement trouvé</p>
              </div>
            )}
          </div>

          {/* Pagination Controls */}
          <div className="pagination-controls">
            <button
              className="pagination-button"
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
            >
              <FaArrowLeft /> Page Précédente
            </button>
            <button
              className="pagination-button"
              onClick={handleNextPage}
              disabled={logements.length < pageSize}
            >
              Page Suivante <FaArrowRight />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DemandeList;
