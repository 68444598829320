import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import "../AuthPages.css";
import { forgetPassword } from "../../../services/apis"; 

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [authStatus, setAuthStatus] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [authMessage, setAuthMessage] = useState("");
  // const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setAuthStatus(null);
    setAuthError(null);

    try {
      await forgetPassword(email);
      setAuthStatus("succeeded");
      setAuthMessage("A password reset link has been sent to your email.");
    } catch (error) {
      setAuthStatus("failed");
      setAuthError("Failed to send password reset link. Please try again.");
      console.error("Forget password error", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
        <div className="auth-card">
          <h2>Mot de passe oublié? 🤔</h2>
          <p>Entrez votre email pour recevoir un lien de réinitialisation</p>

          {authStatus === "failed" && (
            <div className="alert error">{authError}</div>
          )}
          {authStatus === "succeeded" && (
            <div className="alert success">{authMessage}</div>
          )}

          <form onSubmit={onSubmit}>
            <div className="input-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Entrez votre email"
                className={authStatus === "failed" ? "error" : ""}
                required
              />
            </div>

            <button
              type="submit"
              className="auth-submit-btn"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Envoi..." : "Envoyer"}
            </button>
          </form>
        </div>
  );
};

export default ForgetPassword;
