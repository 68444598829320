import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchGeneralData = createAsyncThunk(
  'generalData/fetchGeneralData',
  async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/test/init`);
    return response.data;
  }
);

const generalDataSlice = createSlice({
  name: 'generalData',
  initialState: {
    villeColocInfo: [],
    colocTypes: [],
    roles: [],
    logementTypes: [],
    tags: [],
    languages: [],
    colocStatuts: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGeneralData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGeneralData.fulfilled, (state, action) => {
        state.villeColocInfo = action.payload.villeColocInfo;
        state.colocTypes = action.payload.colocTypes;
        state.roles = action.payload.roles;
        state.logementTypes = action.payload.logementTypes;
        state.tags = action.payload.tags;
        state.languages = action.payload.languages;
        state.colocStatuts = action.payload.colocStatuts;
        state.loading = false;
      })
      .addCase(fetchGeneralData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default generalDataSlice.reducer;
